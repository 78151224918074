export default [
  // 링크결제 생성내역 페이지
  {
    path: '/settlement/link-create-history',
    name: 'LinkCreateHistoryList',
    component: () => import('@/components/settlement/LinkCreateHistoryList'),
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  // 주문결제내역 페이지
  {
    path: '/settlement/order-payment-history',
    name: 'OrderPaymentHistoryList',
    component: () => import('@/components/settlement/OrderPaymentHistoryList'),
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  // 가상계좌 발급내역 페이지
  {
    path: '/settlement/va-create-history',
    name: 'VaCreateHistoryList',
    component: () => import('@/components/settlement/VaCreateHistoryList'),
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  // 가상계좌 입급내역 페이지
  {
    path: '/settlement/va-deposit-history',
    name: 'VaDepositHistoryList',
    component: () => import('@/components/settlement/VaDepositHistoryList'),
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  // 매출 관리 페이지(구버전입니다 현재는 /settlement/sales 로 하는게 최신입니다 구버전도 legacy-sales로 들어갈수있습니다 )
  {
    path: '/settlement/legacy-sales',
    name: 'SalesManagementList',
    component: () => import('@/components/settlement/SalesManagementList'),
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  // 정산관리 페이지
  {
    path: '/settlement/calculate',
    name: 'CalculateManagementList',
    component: () => import('@/components/settlement/CalculateManagementList'),
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },

  // 청구관리 페이지
  {
    path: '/settlement/billing',
    name: 'BillingManagementList',
    component: () => import('@/components/settlement/BillingManagementList'),
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },

  // 미결제액 * 지연손해금 현황 페이지
  {
    path: '/settlement/latefee',
    name: 'LateFeeBoardList',
    component: () => import('@/components/settlement/LateFeeBoardList'),
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },

  // 매출 관리 페이지
  {
    path: '/settlement/sales',
    name: 'SalesManagementList2',
    component: () => import('@/components/settlement/SalesManagementList2'),
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  // 예치금 관리 페이지
  {
    path: '/settlement/rental-deposit',
    name: 'RentalDepositManagementList',
    component: () => import('@/components/settlement/RentalDepositManagementList'),
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  // 상계처리(배부) 내역 관리 페이지
  {
    path: '/settlement/rental-deposit-processing/:id',
    name: 'OffsettingProcessingList',
    component: () => import('@/components/settlement/OffsettingProcessingList'),
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  // 입금/배부 관리 페이지
  {
    path: '/settlement/deposit-distribution',
    name: 'DepositDistributionList',
    component: () => import('@/components/settlement/DepositDistributionList'),
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  // 결제처리(배부) 내역 관리 페이지
  {
    path: '/settlement/deposit-distribution-processing/:id',
    name: 'DistributionProcessingList',
    component: () => import('@/components/settlement/DistributionProcessingList'),
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  // 호별 매출 현황
  {
    path: '/settlement/status-sales-room',
    name: 'SalesRoomList',
    component: () => import('@/components/settlement/SalesRoomList'),
    meta: {
      requiresAuth: false,
      fullScreen: false,
    },
  },
  // 복합기 매출 현황
  {
    path: '/settlement/multi-print-monthly',
    name: 'MultiPrintMonthly',
    component: () => import('@/components/settlement/MultiPrintMonthly'),
    meta: {
      requiresAuth: false,
      fullScreen: false,
    },
  },
]
