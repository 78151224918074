const getDefaultState = () => {
    return {
        list: null,
        count: 0,
        originFilter: {},
    }
}
export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        totalPages: state => {
            const limit = state.originFilter?.limit || 20
            const count = state.count || 0
            return Math.ceil(count / limit)
        },
    },
    mutations: {
        resetState(state) {
            Object.assign(state, getDefaultState())
        },
        setResponse(state, data) {
            state.list = data.memberships
            state.count = data.totalCount
        },
        setOriginFilter(state, filter) {
            state.originFilter = JSON.parse(JSON.stringify(filter))
        },
    },
    actions: {
        async getManagerList(context, payload) {
            let response = null

            try {
                response = await this._vm.get(
                    `${this._vm.SPARK_ONE_CREW_API_URL}manager`
                    , payload
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }

            return response
        },
        async getManagerInfo(context, payload) {
            let response = null

            try {
                response = await this._vm.get(
                    `${this._vm.SPARK_ONE_CREW_API_URL}manager/${payload.managerId}`
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }

            return response
        },
        async postManager(context, payload) {
            let response = null

            try {
                response = await this._vm.post(
                    `${this._vm.SPARK_ONE_CREW_API_URL}manager`,
                    payload
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }

            return response
        },
        async postManagerMOB (context, payload) {
            let response = null

            try {
                response = await this._vm.post(
                    `${this._vm.SPARK_ONE_CREW_API_URL}manager-mob`,
                    payload
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }

            return response
        },
        async putManager(context, payload) {
            let response = null

            try {
                response = await this._vm.put(
                    `${this._vm.SPARK_ONE_CREW_API_URL}manager/${payload.managerId}`,
                    payload
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }

            return response
        },
        async getList(context, payload) {
            let response = null
            try {
                response = await this._vm.get(
                    `${this._vm.SPARK_ONE_CREW_API_URL}manager/account-membership`,
                    payload,
                )
                commit('setResponse', response.data)
                commit('setOriginFilter', payload)
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }
            return response
        },
        async putManagerStatus(context, payload) {
            let response = null

            try {
                response = await this._vm.put(
                    `${this._vm.SPARK_ONE_CREW_API_URL}manager-status/${payload.managerId}`,
                    payload
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }

            return response
        },
        async getManagerExcelList (context, payload) {
            let response = null

            try {
              response = await this._vm.get(
                `${this._vm.SPARK_ONE_CREW_API_URL}manager/manager-list/excel`
                , payload
              )
            } catch (error) {
              if (error.response) {
                response = error
              } else {
                throw error
              }
            }

            return response
        },
        async getEmailReSend (context, payload) {
            let response = null
            try {
                response = await this._vm.get(
                    `${this._vm.SPARK_ONE_CREW_API_URL}manager/${payload.managerId}/email`
                    , payload
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }

            return response
        },
    },
}
