<template>
  <div class="popup-wrap">
    <div @click.self="close('outside')" class="bg"></div>
    <div class="popup-container"  :style="{overflow}" ref="popup">
      <div v-show="title" class="popup-title">
        {{ title }}
        <slot name="button"></slot>
      </div>
      <div v-show="description" class="description" v-html="description"></div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Popup',
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    closeOnClickOutside: {
      type: Boolean,
      default: true,
    },
    overflow: {
      type: String,
      default: 'auto',
    },
  },
  mounted () {
    if (this.$refs.popup.querySelector('button:last-child')) {
      this.$refs.popup.querySelector('button:last-child').focus()
    }
  },
  methods: {
    close (from) {
      if (from == 'outside' && this.closeOnClickOutside) {
        this.$emit('close')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.bg {
  position: fixed;
  top: 0;
  left:0;
  right: 0;
  bottom: 0;
  background-color: #0008;
  z-index: 101;
}
.popup-wrap {
  position: fixed !important;
  height: 100vh;
  top: 0;
  left:0;
  right: 0;
  bottom: 0;
  z-index: 102;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
.popup-container {
  z-index: 103;
  min-width: 100px;
  width: fit-content;
  height: fit-content;
  max-height: calc(100vh - 90px);
  max-width: calc(100vw - 100px);
  // overflow: auto;
  padding: 38px 38px 24px 38px;
  border: 1px solid #cccccc;
  border-radius: var(--theme-input-radius);
  box-shadow: 0 0 20px 0 #999999;
  // background: #fff;
  background: var(--theme-background-color);
  .popup-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 24px;
    font-weight: 600;
    padding-bottom: 6px;
  }
  .description {
    font-size: 15px;
    color: #888899;
    white-space: pre-line;
    // padding-bottom: 10px;
    /deep/.red {
      color:#ff0000;
    }
  }
  .button-group {
    margin-top:10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
</style>
