const getDefaultState = () => {
  return {
    contractAdditionalOriginFilter: {},
    list: [],
    count: 0,
  }
}
export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    totalPages: (state) => {
      const limit = state.contractAdditionalOriginFilter?.limit || 20
      const count = state.count || 0
      return Math.ceil(count / limit)
    },
  },
  mutations: {
    responseResetState (state) {
      state.list = []
      state.count = 0
      state.contractAdditionalOriginFilter = {}
    },
    setResponse (state, data) {
      state.list = data.list
      state.count = data.totalCount.count
    },
    setContractAdditionalOriginFilter (state, filter) {
      state.contractAdditionalOriginFilter = JSON.parse(JSON.stringify(filter))
    },
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    // 기업서비스 상품 부가서비스
    async postInfo (context, payload) {
      let response = null
      try {
        response = await this._vm.post(
          `${this._vm.SPARK_ONE_CREW_API_URL}account/additional-service`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async putInfo (context, payload) {
      let response = null
      try {
        response = await this._vm.put(
          `${this._vm.SPARK_ONE_CREW_API_URL}account/additional-service/${payload.contract.contractId}`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getList (context, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}account/additional-service-list/${payload.membershipId}`,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getInfo (context, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}account/additional-service/${payload.contractId}`,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    // 계약인원/크레딧 생성
    async postContractAdditionalGiveReq (context, payload) {
      let response = null
      try {
        response = await this._vm.post(
          `${this._vm.SPARK_ONE_CREW_API_URL}account/additional-give-req`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getContractList (context, payload) {
      let response = null
      try {
        const membershipId = payload.membershipId
        delete payload.membershipId
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}account/contract-list/${membershipId}`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getContractMemberInfo (context, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}account/contract-member-info`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getContractCreditVisitCountInfo (context, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}account/contract-credit-visit-count-info`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getGoodsInfo (context, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}account/goods-info/${payload.goodsType}`,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    // 계약인원 지급요청 리스트 조회
    async getContractMemberList ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}account/contract-member-list`,
          payload,
        )
        commit('setResponse', response.data)
        commit('setContractAdditionalOriginFilter', payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    // 계약인원 지급요청 리스트 조회
    async getAddContractMemberList (context, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}account/add-contract-member-list`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    // 크레딧/횟수 지급요청 리스트 조회
    async getCreditVisitCountList ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}account/credit-visit-count-list`,
          payload,
        )
        commit('setResponse', response.data)
        commit('setContractAdditionalOriginFilter', payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getBranchHaServers ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}account/iot/branch-ha-servers`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
  },
}
