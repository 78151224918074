export default {
  namespaced: true,
  state: {
    info: {},
    list: [],
    group: {},
    count: 0,
    totalPage: 0,
  },
  getters: {

  },
  mutations: {
    resetState (state) {
      Object.assign(state.count, 0)
      Object.assign(state.list, [])
    },
    setDataInfo (state, data) {
      state.info = data || {}
    },
    setDataList (state, data) {
      state.list = data || {}
    },
    setDataCount (state, data) {
      state.count = data || 0
    },
    setDataGroup (state, data) {
      state.group = data || {}
    },
    setDataTotalPage (state, data) {
      state.totalPage = data || 0
    },
  },
  actions: {
    getDbColsCodeList (context, payload) {

      return new Promise((resolve, reject) => {

        this._vm.get(`${this._vm.SPARK_ONE_CREW_API_URL}common/db-cols-code`, payload)
          .then((response) => {
            context.commit('setDataList', response.data.dbColsCodeList || [])
            context.commit('setDataCount', response.data.dbColsCodeCount || 0)
            context.commit('setDataGroup', response.data.dbColsCodeGroup || {})

            if (payload && typeof payload.limit !== 'undefined') context.commit('setDataTotalPage', Math.ceil((response.data.dbColsCodeCount || 0) / (payload.limit)))

            resolve(response)
          })
          .catch((error) => reject(error))

      })

    },
    getDbColsCodeInfo (context, payload) {
      context.commit('setDataInfo', {})

      return new Promise((resolve, reject) => {

        this._vm.get(`${this._vm.SPARK_ONE_CREW_API_URL}common/db-cols-code/info`, payload)
          .then(response => {
            context.commit('setDataInfo', response.data.dbColsCodeInfo)
            resolve(response)
          })
          .catch(error => reject(error))

      })

    },
    setDbColsCode (context, payload) {

      return new Promise((resolve, reject) => {

        this._vm.post(`${this._vm.SPARK_ONE_CREW_API_URL}common/db-cols-code`, payload)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => reject(error))

      })

    },
    putDbColsCode (context, payload) {

      return new Promise((resolve, reject) => {

        this._vm.put(`${this._vm.SPARK_ONE_CREW_API_URL}common/db-cols-code`, payload)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))

      })

    },
  },
}
