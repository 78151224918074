export default {
  namespaced: true,
  actions: {
    async upsertHaServer ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.put(
          `${this._vm.SPARK_ONE_CREW_API_URL}space/iot/server`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getHaServer ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}space/iot/branch/${payload.branchId}/floor/${payload.floorType}/room/${payload.roomId}/server`,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    async getDevices ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}space/iot/server/${payload.haServerNo}/devices`,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async createDevice ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.post(
          `${this._vm.SPARK_ONE_CREW_API_URL}space/iot/device`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        }
      }
      return response
    },
    async updateDevice ({ commit }, payload) {
      let response = null
      const currentDeviceId = payload.currentDeviceId
      delete payload.currentDeviceId
      try {
        response = await this._vm.put(
          `${this._vm.SPARK_ONE_CREW_API_URL}space/iot/device/${currentDeviceId}`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        }
      }
      return response
    },
  },
}
