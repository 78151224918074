const getDefaultState = () => {
  return {
    invoices: [],
    tenants: [],
    billingYearMonths: [],
    originFilter: {},
  }
}
export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    invoices: (state) => state.invoices,
    tenants: (state) => state.tenants,
    billingYearMonths: (state) => state.billingYearMonths,
  },
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setInvices (state, data) {
      state.invoices = data.invoices
    },
    setBillingYearMonths (state, data) {
      state.billingYearMonths = data.billingYearMonths
    },
    setTenants (state, data) {
      state.tenants = data.tenants
    },
  },
  actions: {
    async getAccountTenants ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}account/${payload.membershipId}/tenants`,
        )
        commit('setTenants', response.data)
        commit('setInvices', [])
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getAccountTenantBillingYearMonths ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}account/${payload.membershipId}/tenant/${payload.tenantId}/billing-year-months`,
        )
        commit('setBillingYearMonths', response.data)
        commit('setInvices', [])
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    async getInvoices ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}account/${payload.membershipId}/tenant/${payload.tenantId}/billing-year-month/${payload.billingYearMonth}/invoices`,
        )
        commit('setInvices', response.data)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    async postInvoice ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.post(
          `${this._vm.SPARK_ONE_CREW_API_URL}account/invoice`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
  },
}
