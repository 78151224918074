<template>
<div class="gap">
  <!-- select elem for inject options (slot) from outer scope -->
  <select
  ref="select"
  class="select"
  :value="value"
  tabindex="-1"
  :multiple="multiple"
  :id="uuid"
  v-on="listeners"
  v-bind="attrs">
    <slot></slot>
  </select>
  <!-- multiple select -->
  <div
  v-if="multiple"
  class="selectbox"
  :class="{disabled, readonly}">
    <ul
    ref="focused"
    tabindex="0"
    @focusin="inputFocused = true"
    @focusout="inputFocused = false"
    class="selected-items">
      <li
      v-for="item in selectedItems"
      class="item"
      :title="text(item)"
      @click="toggleOptionSelection(option(item))"
      :key="item">
      {{text(item)}}
      </li>
      <input
      v-show="isOptionsOpened || selectedItems.length == 0"
      @focusin="inputFocused = true"
      @focusout="inputFocused = false"
      class="item input"
      type="text"
      :placeholder="(!selectedItems.length || isOptionsOpened) ? placeholder : ''"
      :value="searchText"
      @input="inputIME" />
    </ul>
    <ul
    class="options"
    ref="options"
    tabindex="0"
    @focusin="optionHovered = true"
    @blur="optionHovered = false"
    @mouseleave="optionHovered = false">
      <li v-if="searchedOptions.length == 0" class="no-item">일치하는 항목이 없습니다</li>
      <li
      v-else
      v-for="so in searchedOptions"
      :key="so.value"
      v-html="so.text"
      class="option"
      :class="{selected : selectedItems.includes(so.value)}"
      @click="toggleOptionSelection(so)" />
    </ul>
  </div>
  <!-- single select -->
  <div
  v-else
  class="selectbox"
  :class="{disabled, readonly}">
    <ul ref="focused" tabindex="0" class="selected-items" @click="$refs.input.focus()" @focusin="inputFocused = true" @focusout="inputFocused = false">
      <li
      v-if="selectedItem !== undefined"
      :title="text(selectedItem)"
      class="item">
        {{text(selectedItem)}}
      </li>
      <li
      v-else-if="selectedItem === undefined"
      :title="unbindLabel"
      class="item">
      {{unbindLabel}}
      </li>
      <input
      v-show="isOptionsOpened || selectedItem === undefined"
      @focusin="inputFocused = true"
      @focusout="inputFocused = false"
      ref="input"
      class="item input"
      type="text"
      :placeholder="(!selectedItem || isOptionsOpened) ? placeholder : ''"
      :value="searchText"
      @input="inputIME" />
    </ul>
    <ul
    class="options"
    :class="{forceHoverOff}"
    ref="options"
    tabindex="0"
    @focusin="optionHovered = true"
    @blur="optionHovered = false"
    @mouseleave="optionHovered = false">
      <li
      tabindex="0"
      v-if="searchedOptions.length == 0"
      class="no-item">
        일치하는 항목이 없습니다
      </li>
      <li
      v-else
      v-for="so in searchedOptions"
      :key="so.value"
      v-html="so.text"
      class="option"
      :class="{selected : selectedItem == so.value, disabled: so.disabled}"
      @click="toggleOptionSelection(so)" />
    </ul>
  </div>
</div>
</template>

<script>
import escapeRegExp from 'lodash.escaperegexp'
export default {
  name: 'SpSelect2',
  inheritAttrs: false,
  props: {
    value: { type: null, default: undefined },
    id: { type: String, default: '' },
    placeholder: { type: String, default: '검색어를 입력하세요' },
    multiple: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
  },
  data () {
    return {
      refreshKey: 0,
      uuid: null,
      observer: null,
      elOptions: null, // options from slot (injected from mutation observer)
      searchText: '',
      inputFocused: false,
      optionHovered: false,
      isOptionsOpened: false,

      searchedOptions: [], // searched options by searchText
      selectedItems: [], // multiple selected values
      selectedItem: '', // single selected value
      unbindLabel: '',
      forceHoverOff: false,
    }
  },
  created () {
    this.uuid = this.id
    if (this.uuid == '') {
      this.uuid = this.uuidv4()
    }
  },
  mounted () {
    this.__watchOptions()
  },
  beforeDestroy () {
    // Clean up
    if (this.observer) this.observer.disconnect()
  },
  methods: {
    focus () {
      this.$refs.focused.focus()

    },
    calculateOptionOpened () {
      this.debounce('calculateOptionOpened', () => {
        this.isOptionsOpened = this.inputFocused || this.optionHovered
      }, 10)
    },
    toggleOptionSelection (option) {
      let newv, oldv
      if (this.multiple) {
        oldv = JSON.stringify(this.selectedItems)
        const selectedOption = this.elOptions.find(o => o.value === option.value)
        selectedOption.selected = !selectedOption.selected
        for (const o of this.$refs.select.options) {
          if (o.value === option.value) {
            o.selected = !o.selected
            break
          }
        }
        this.selectedItems = this.elOptions.filter(o => o.selected).map(o => o.value)
        newv = JSON.stringify(this.selectedItems)
        this.$emit('input', this.selectedItems)
      } else {
        oldv = this.selectedItem
        newv = option.value
        this.selectedItem = option.value
        for (const o of this.$refs.select.options) {
          o.selected = o.value === option.value
        }
        this.$emit('input', this.selectedItem)
        document.activeElement.blur()
        this.forceHoverOff = true
        setTimeout(() => {
          this.forceHoverOff = false
        }, 100)
      }
      // dispatch change event
      if (this.multiple) {
        if (JSON.stringify(newv) === JSON.stringify(oldv)) return
      } else {
        if (newv === oldv) return
      }
      const event = new Event('change', { bubbles: true })
      this.$refs.select.dispatchEvent(event)
    },
    inputIME (e) {
      this.searchText = e.target.value
    },
    __watchOptions () {
      this.observer = new MutationObserver(
        this.__applyOptions,
      )
      // Setup the observer
      this.observer.observe(
        this.$refs.select,
        {
          childList: true,
          subtree: true, // 모든 하위 노드에서도 변경을 감지합니다.
          characterData: true, // 텍스트 노드의 변경을 감지합니다.
        },
      )
      this.__applyOptions()

    },
    __applyOptions () {
      const temp = Array.apply(null, this.$refs.select?.options)
      this.elOptions = temp.map(
        (option) => {
          return {
            value: option.value,
            text: option.text,
            selected: (
              !this.multiple
                ? this.value == option.value
                : this.value.includes(option.value)
            ),
            disabled: option.disabled,
          }
        },
      )

      if (this.multiple) {
        //
      } else {
        this.selectedItem = this.elOptions.find(o => o.selected)?.value
        if (this.selectedItem === undefined) {
          this.unbindLabel = this.elOptions[0]?.text
        }
      }
      this.searchedOptions = this.elOptions

    },
    __createFuzzyMatcher (input) {
      const pattern = input
        .split('')
        .map(this.__ch2pattern)
        .map((pattern) => '(' + pattern + ')')
        .join('.*?')
      return new RegExp(pattern)
    },
    __ch2pattern (ch) {
      const offset = 44032 /* '가'의 코드 */
      // 한국어 음절
      if (/[가-힣]/.test(ch)) {
        const chCode = ch.charCodeAt(0) - offset
        // 종성이 있으면 문자 그대로
        if (chCode % 28 > 0) {
          return ch
        }
        const begin = Math.floor(chCode / 28) * 28 + offset
        const end = begin + 27
        return `[\\u${begin.toString(16)}-\\u${end.toString(16)}]`
      }
      // 한글 자음
      if (/[ㄱ-ㅎ]/.test(ch)) {
        const con2syl = {
          ㄱ: '가'.charCodeAt(0),
          ㄲ: '까'.charCodeAt(0),
          ㄴ: '나'.charCodeAt(0),
          ㄷ: '다'.charCodeAt(0),
          ㄸ: '따'.charCodeAt(0),
          ㄹ: '라'.charCodeAt(0),
          ㅁ: '마'.charCodeAt(0),
          ㅂ: '바'.charCodeAt(0),
          ㅃ: '빠'.charCodeAt(0),
          ㅅ: '사'.charCodeAt(0),
        }
        const begin = con2syl[ch] || (ch.charCodeAt(0) - 12613) * 588 + con2syl['ㅅ']
        const end = begin + 587
        return `[${ch}\\u${begin.toString(16)}-\\u${end.toString(16)}]`
      }
      // 그 외엔 그대로
      // escapeRegExp는 lodash에서 훔쳐옴
      return escapeRegExp(ch)
    },
  },
  computed: {
    listeners () {
      const { input, ...listeners } = this.$listeners
      return listeners
    },
    attrs () {
      return this.$attrs
    },
    text () {
      return (value) => {
        return this.elOptions?.find((i) => i.value === value)?.text // ?? value
      }
    },
    option () {
      return (value) => {
        return this.elOptions.find((i) => i.value === value)
      }
    },
  },
  watch: {
    value: {
      immediate: true,
      handler (newv, oldv) {
        if (this.multiple) {
          if (JSON.stringify(newv) === JSON.stringify(oldv)) return
          this.selectedItems = newv
        } else {
          if (newv === oldv) return
          this.selectedItem = newv
        }
        this.__applyOptions()
        // const event = new Event('change', { bubbles: true })
        // this.$refs.select.dispatchEvent(event)
      },
    },
    isOptionsOpened (value) {
      if (!value) {
        this.$nextTick(() => {
          this.searchText = ''
        })
      }
    },
    inputFocused: {
      handler () {
        this.calculateOptionOpened()
      },
    },
    optionHovered: {
      handler () {
        this.calculateOptionOpened()
      },
    },
    searchText: {
      handler () {
        this.$nextTick(() => {
          this.$refs.options.scrollTop = 0
        })
        const regex = this.__createFuzzyMatcher(this.searchText)

        this.searchedOptions = this.elOptions.filter(
          (option) => {
            return regex.test(option.text)
          },
        ).map((option) => {
          let orderWeight = 0 // lower is better

          const replacer = (match, ...groups) => {
            const letters = groups.slice(0, this.searchText.length)
            let lastIndex = 0
            const highlighted = []
            for (let i = 0, l = letters.length; i < l; i++) {
              const idx = match.indexOf(letters[i], lastIndex)
              highlighted.push(match.substring(lastIndex, idx))
              highlighted.push(`<mark>${letters[i]}</mark>`)
              lastIndex = idx + 1
              orderWeight += (groups[groups.length - 1].indexOf(groups[groups.length - 3]))
              if (this.searchText[i] == letters[i]) {
              // 정확히 일치 시 -1
                orderWeight -= 1
              }
            }
            return highlighted.join('')
          }
          const text = option.text.replace(regex, replacer)

          return { value: option.value, text, orderWeight }
        }).sort((a, b) => {
          return a.orderWeight - b.orderWeight
        })
      },
    },

  },
}
</script>
<style lang="scss">
td:has(.selectbox) {
  overflow: visible !important;
}
</style>
<style lang="scss" scoped>
ul, li, input {
  padding: 0;
  margin: 0;
  border: 0;
}

.gap {
  line-height: 16px;
  max-width: 100%;
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 60px !important;
  box-sizing: border-box;
  .select {
    pointer-events: none;
    position: absolute;
    left: 0px;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  .selectbox {
    position: relative;
    margin: 0 auto;
    // display: flex;
    // flex-direction: column;
    width: calc(100% - 5px);
    .selected-items {

      &:before {
        content: "";
        position: absolute;
        top: 50%;
        right: 8px;
        width: 0;
        height: 0;
        margin-top: -2.5px;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid var(--theme-primary-color);
      }
      font-size: 14px;
      line-height: 16px;
      position: relative;
      margin:4px 0;
      border: 1px solid #ddd;
      border-radius: var(--theme-input-radius);
      box-sizing: border-box;
      padding: 3px 20px 3px 2px;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      gap: 2px 5px;
      background: #fff;
      &:focus-within {
        border-color: var(--theme-primary-color);
        animation: shadow 0.1s ease-in-out forwards;
      }
      &:focus-within+.options, &+.options:hover
      {
        animation: shadow 0.1s ease-in-out forwards;
        border-color: var(--theme-primary-color);
        transform: scale(1);
        &.forceHoverOff {
          transform: scale(0);
        }
      }
      .item {
        height: 16px;
        line-height: 16px;
        cursor: pointer;
        padding: 1px 4px;
        border-radius: var(--theme-input-radius);
        font-weight: 500;
        word-break: break-all;
        list-style: none;
        color: #fff;
        background: var(--theme-primary-color);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .input {
        text-indent: 5px;
        padding :1px 0;
        height: 16px;
        line-height: 16px;
        color: black;
        background: #fff;
        min-width: 1px;
        flex-basis: 1px;
        flex-grow: 1;
        flex-shrink: 1;
        outline: none;
      }
    }
    .options {
      z-index: 3;
      position: absolute;
      transform: scale(0);
      overflow: auto;
      box-sizing: border-box;
      width: 100%;
      max-height: 200px;
      border: 1px solid #ddd;
      border-radius: var(--theme-input-radius);
      background: #ddd;
      display: flex;
      flex-direction: column;
      scroll-behavior: smooth;
      overscroll-behavior: contain;
      scroll-snap-type: y mandatory;
      gap: 1px 0;
      .option {
        line-height: 1;
        text-indent: 12px;
        cursor: pointer;
        padding: 4px;
        font-weight: normal;
        word-break: break-all;
        list-style: none;
        color: #000;
        background: #fff;
        scroll-snap-align: start;
        &:hover {
          box-shadow: inset 0px -10px 20px #8a8a8a22;
        }
        &.selected {
          // font-weight: 600;
          font-weight: 600;
          text-indent: 0;
          background: var(--theme-primary-color);
          color: var(--theme-background-color);
          &:before {
            display: inline-block;
            width: 12px;
            font-weight: 600;
            content: '✓';
          }
        }
        &.disabled {
          color: var(--theme-light-color);
          pointer-events: none;
        }
      }
      .no-item {
        line-height: 16px;
        text-align: center;
        padding: 10px 4px;
        list-style: none;
        background: #fff;
        color: var(--theme-primary-color);
        word-break: keep-all;
      }
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.7;
      .selected-items {
        // background: #eee;
        background: #F8F7EFaa;
        &:focus-within, &:focus {
          border: 1px solid #bbb;
          animation: none;
        }
        &:before {
          content: "";
          position: absolute;
          top: 50%;
          right: 8px;
          width: 0;
          height: 0;
          margin-top: -2.5px;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid #bbb;
        }
      }
      .item {
        background: #bbb !important;
      }
      .options {
        display: none;
        transform: scale(0) !important;
      }
    }
    &.readonly {
      pointer-events: none;
      opacity: 0.7;
      .selected-items {
        // background: #eee;
        background: #F8F7EFaa;
        &:focus-within, &:focus {
          border: 1px solid #bbb;
          animation: none;
        }
        &:before {
          content: "";
          position: absolute;
          top: 50%;
          right: 8px;
          width: 0;
          height: 0;
          margin-top: -2.5px;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid #bbb;
        }
      }
      .input {display: none;}
      .options {
        display: none;
        transform: scale(0) !important;
      }
    }
  }
}
</style>
