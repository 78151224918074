import aws from 'aws-sdk'
import Excel from 'exceljs'
import md5 from 'md5'
import moment from 'moment'
import streamSaver from 'streamsaver'
import Vue from 'vue'
import store from '../store'

Vue.mixin({
  data () {
    return {
      DEBOUNCE_TIMER: {},
    }
  },
  methods: {
    log (e, name = '>>>') {
      console.log(name + '', this.JSONPrettyPrint(e))
      return e
    },
    /**
     * 숫자 3자리마다 쉼표찍어 반환
     *
     * @param val Number 숫자
     * @return String
     */
    numberFormat (val) {
      return this.getFormattedNumber(val)
    },
    formatBytes (bytes) {
      if (bytes < 1024) {
        return bytes + ' Byte'
      } else if (bytes < 1048576) {
        const kilobytes = (bytes / 1024).toFixed(2)
        return kilobytes + ' KB'
      } else if (bytes < 1073741824) {
        const megabytes = (bytes / 1048576).toFixed(2)
        return megabytes + ' MB'
      } else {
        const gigabytes = (bytes / 1073741824).toFixed(2)
        return gigabytes + ' GB'
      }
    },
    getFormattedNumber (val) {
      if (typeof val !== 'number') {
        return '0'
      }
      const isMinus = val < 0
      const formattedValue = String(Math.abs(val))
        .replace(/\D/g, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')

      return isMinus ? `-${formattedValue}` : formattedValue
    },
    getFormattedPhoneNumber (val) {
      return val.replace(/(\d{3})([\d*]{3,4})([\d*]{4})/, '$1-$2-$3')
    },
    getFormatResidentNumber (data) {
      // 숫자만 추출하여 형식에 맞게 "-" 문자를 추가
      return data.replace(/\D/g, '').replace(/(\d{6})(\d{7})/, '$1-$2')
    },
    getFormattedBusinessNumber (val) {
      // 입력 = 1234567890
      // 출력 = 123-45-67890
      const data = val || 0
      const stringOnly = data.toString()
      return stringOnly.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3')
    },
    JSONPrettyPrint (v) {
      return JSON.stringify(v, null, 2)
    },
    cleanData (obj) {
      const newObj = {}
      Object.keys(obj).forEach((prop) => {
        if (obj[prop] || (!obj[prop] && obj[prop] === 0)) {
          newObj[prop] = obj[prop]
        }
      })
      return newObj
    },
    deepCopy (obj) {
      // 객체 deepCopy
      return JSON.parse(JSON.stringify(obj))
    },
    isSubsetOf (base, sample) {
      // sample 객체가 base에 완전 포함되는 객체인지 여부 체크
      return sample.every((number) => base.includes(number))
    },
    changeObjectToOption (obj = {}) {
      return Object.entries(obj).map(([key, value]) => {
        const x = {}
        x.value = key
        x.text = value
        return x
      })
    },
    isMoreThanMax (value, max) {
      return max !== null && value > max
    },
    isLessThanMin (value, min) {
      return min !== null && value < min
    },
    isNumberInteger (value) {
      // 숫자가 정수인지 여부 체크
      return Number.isInteger(parseFloat(value))
    },
    changeToMinimum (_value, min) {
      if (typeof _value === 'object' || _value == '') {
        return null
      }
      if (isNaN(_value)) {
        return 0
      }
      if (this.isLessThanMin(_value, min)) {
        return parseFloat(min)
      }
      return parseFloat(_value)
    },
    changeToMaximum (_value, max) {
      if (typeof _value === 'object' || _value == '') {
        return null
      }
      if (isNaN(_value)) {
        return 0
      }
      if (this.isMoreThanMax(_value, max)) {
        return parseFloat(max)
      }
      return parseFloat(_value)
    },
    getNumberLimitedDecimal (_value, limit = 0) {
      // 소수점 자릿 수 지정시 해당 값 까지 값을 가져옴 (ex. limit이 2일 경우 소수점 2자리까지)
      if (isNaN(_value) || typeof _value === 'object') {
        return null
      }
      return (
        Math.floor(parseFloat(_value) * Math.pow(10, limit))
        / Math.pow(10, limit)
      )
    },
    isEmptyObject (param) {
      return Object.keys(param).length === 0 && param.constructor === Object
    },
    getUniqueItems (items) {
      // 배열 내 객체 중복 제거
      const obj = items.map(JSON.stringify)
      return Array.from(new Set(obj)).map(JSON.parse)
    },
    generateTimeSlots: function () {
      const startTime = moment('00:00:00', 'HH:mm:ss')
      const endTime = moment('24:00:00', 'HH:mm:ss')
      const currentTime = moment(startTime)
      const timeSlots = []

      while (currentTime.isSameOrBefore(endTime)) {
        const timeSlot = {
          start: currentTime.format('HH:mm:ss'),
          startText: currentTime.format('HH:mm'),
          end: currentTime.add(30, 'minutes').format('HH:mm:ss'),
          endText: currentTime.format('HH:mm'),
        }
        timeSlots.push(timeSlot)
      }

      const newTimeSlots = timeSlots.map((x) => {
        return {
          start: x.start,
          startText: x.startText,
          end: x.start,
          endText: x.startText,
        }
      })

      newTimeSlots[newTimeSlots.length - 1].start = '23:59:58'
      newTimeSlots[newTimeSlots.length - 1].startText = '24:00'
      newTimeSlots[newTimeSlots.length - 1].end = '23:59:59'
      newTimeSlots[newTimeSlots.length - 1].endText = '24:00'

      return newTimeSlots
    },
    getIndexNo (totalCount, index, limit, page) {
      return totalCount - (page - 1) * limit - index
    },
    getTodayDate (date = new Date()) {
      return new Date(date.getFullYear(), date.getMonth(), date.getDate())
    },
    debounce (key, fn, wait) {
      this.debounceForceCancellation(key)
      this.DEBOUNCE_TIMER[key] = setTimeout(fn, wait)
    },
    debounceForceCancellation (key) {
      if (this.DEBOUNCE_TIMER?.[key]) {
        clearTimeout(this.DEBOUNCE_TIMER[key])
      }
    },
    throttle (fn, wait) {
      let throttled = false
      return function (...args) {
        if (!throttled) {
          fn.apply(this, args)
          throttled = true
          setTimeout(() => {
            throttled = false
          }, wait)
        }
      }
    },
    getCookie (name) {
      const matches = document.cookie.match(
        new RegExp(
          '(?:^|; )'
            + name.replace(/([.$?*|{}()[]\\\/\+^])/g, '\\$1')
            + '=([^;]*)',
        ),
      )
      return matches ? decodeURIComponent(matches[1]) : undefined
    },
    setCookie (name, value, options = {}) {
      options = {
        path: '/',
        // 필요한 경우, 옵션 기본값을 설정할 수도 있습니다
        ...options,
      }

      if (options.expires instanceof Date) {
        options.expires = options.expires.toUTCString()
      }

      let updatedCookie
        = encodeURIComponent(name) + '=' + encodeURIComponent(value)

      for (const optionKey in options) {
        updatedCookie += '; ' + optionKey
        const optionValue = options[optionKey]
        if (optionValue !== true) {
          updatedCookie += '=' + optionValue
        }
      }

      document.cookie = updatedCookie
    },
    deleteCookie (name) {
      this.setCookie(name, '', {
        'max-age': -1,
      })
    },
    /**
     * Downloads a file from the specified URL by creating a temporary link element and triggering a download.
     *
     * @param {string} url - The URL of the file to download.
     * @return {Promise<void>} - A promise that resolves when the download is complete.
     */
    async download (url) {
      const blob = await fetch(url).then((r) => r.blob())
      url = window.URL.createObjectURL(blob)
      const download = document.createElement('a')
      download.href = url
      download.download = url.split('/').pop()
      document.body.appendChild(download)
      download.click()
      document.body.removeChild(download)
    },
    async downloadAtFileName (url, downloadName) {
      const blob = await fetch(url).then((r) => r.blob())
      url = window.URL.createObjectURL(blob)
      const download = document.createElement('a')
      download.href = url
      download.download = downloadName
      document.body.appendChild(download)
      download.click()
      document.body.removeChild(download)
    },
    /**
     * 엑셀 파일 읽어오기
     *
     * 급하게 새로 짯는데 성능 테스트는 안해봣습니다
     * 누가 10만 row 이상 엑셀 읽기 테스트 해주실분? (성능이야 사용자 컴퓨터 성능에 달렸겠지만...)
     *
     * @param data input type=file e.target.files[0]
     * (인풋태그 file을 전달하면 Array of Object 로 밷음)
     */
    async readFromExcel (data) {
      const workbook = new Excel.Workbook()
      await workbook.xlsx.load(data)

      const worksheet = workbook.getWorksheet(1)
      const headers = worksheet.getRow(1).values
      const sheetData = []

      worksheet.eachRow((row, rowNumber) => {
        if (rowNumber !== 1) {
          const rowData = {}
          row.eachCell((cell, colNumber) => {
            let cellValue = cell.value

            // Handle hyperlink or mail as plain text
            if (
              cell.type === Excel.ValueType.Hyperlink
              || cell.type === Excel.ValueType.Email
            ) {
              cellValue = cell.text
            }
            rowData[headers[colNumber]] = cellValue
          })
          sheetData.push(rowData)
        }
      })

      return sheetData
    },
    async getExcelHearder (data) {
      const workbook = new Excel.Workbook()
      await workbook.xlsx.load(data)

      const worksheet = workbook.getWorksheet(1)
      const headers = worksheet.getRow(1).values

      return headers
    },
    /**
     * 엑셀 생성 (Frontend)
     * <!> 참고사항 <!>
     * 이놈은 임시적으로 간단한 파일 생성에만 사용하는 것입니다
     * [광고] 대용량 다운로드는? excelerator!
     *
     * @param header Array 타이틀 항목
     * @param fileName String 파일 이름
     * @param dataList Array 엑셀 데이터
     * @param sheetNm String 시트명
     * @param widthList Array width 데이터
     * @param rowPerFile Nunber 분할 다운로드 시 파일당 record 수
     *                   (데이터 10만 이상 생성 시 브라우저 크래시 위험 있음)  앗, 이런!
     *                   서비스워커 스트리밍 처리로 최대한 성능을 개선했지만 단일파일 10row가 한계선이다
     * @param lastAlert Boolean 완료시 알림 여부
     * @param stringTypeColumns Array column명 or index
     *                         (앞에 0이 들어가야하는 텍스트형 숫자로 인식되어야 하는 컬럼명)
     */
    async createExcel (
      header,
      fileName,
      dataList,
      sheetNm,
      widthList = [],
      rowPerFile = 100000,
      lastAlert = true,
      stringTypeColumns = [],
    ) {
      const sleep = function (t) {
        return new Promise((resolve) => setTimeout(resolve, t))
      }
      const today = new Date().toISOString().slice(0, 10).replace(/-/gi, '')
      const columns = []
      let keylist = []
      // if (dataList.length > 0) {
      //   keylist = Object.keys(dataList[0])
      // } else {
      //   await this.alert('데이터가 없습니다')
      //   return
      // }
      if (dataList.length > 0) {
        keylist = Object.keys(dataList[0])
      }
      for (let i = 0; i < header.length; i++) {
        columns.push({
          header: header[i],
          key: keylist[i],
          width: (widthList[i] || 15 * 5.5) / 5.5,
          bold: true,
        })
      }
      let i = 0
      let writer = null
      const splitFileCount = Math.ceil(dataList.length / rowPerFile)
      try {
        if (dataList.length > 0) {
          while (dataList.length) {
            i++
            const dataSlice = dataList.splice(0, rowPerFile)
            const workbook = new Excel.Workbook()
            const sheet = workbook.addWorksheet(sheetNm)

            // sheet.columns 에 직접 push 할 경우 에러 발생 함
            sheet.columns = columns
            sheet.getRow(1).font = { bold: true }
            sheet.addRows(dataSlice)

            // stringTypeColumns에 해당하는 컬럼은 type을 string으로 변환
            if (stringTypeColumns.length > 0) {
              stringTypeColumns.forEach((column) => {
                sheet.getColumn(column).numFmt = '@'
              })
            }

            const fileNo
              = splitFileCount > 1
                ? '_[' + i + ' of ' + splitFileCount + ']'
                : ''
            const file = fileName + '_' + today + fileNo + '.xlsx'

            const fileStream = streamSaver.createWriteStream(file, {
              writableStrategy: new ByteLengthQueuingStrategy({
                highWaterMark: 1,
              }),
              readableStrategy: new ByteLengthQueuingStrategy({
                highWaterMark: 1,
              }),
            })
            writer = fileStream.getWriter()
            const unload = function () {
              writer.abort()
            }
            window.addEventListener('unload', unload)
            await workbook.xlsx.write(writer)
            writer.close()
            window.removeEventListener('unload', unload)
            await sleep(300)
          }
        } else {
          const dataSlice = dataList.splice(0, rowPerFile)
          const workbook = new Excel.Workbook()
          const sheet = workbook.addWorksheet(sheetNm)

          // sheet.columns 에 직접 push 할 경우 에러 발생 함
          sheet.columns = columns
          sheet.getRow(1).font = { bold: true }
          sheet.addRows(dataSlice)
          const fileNo
            = splitFileCount > 1 ? '_[' + i + ' of ' + splitFileCount + ']' : ''
          const file = fileName + '_' + today + fileNo + '.xlsx'

          const fileStream = streamSaver.createWriteStream(file, {
            writableStrategy: new ByteLengthQueuingStrategy({
              highWaterMark: 1,
            }),
            readableStrategy: new ByteLengthQueuingStrategy({
              highWaterMark: 1,
            }),
          })
          writer = fileStream.getWriter()
          const unload = function () {
            writer.abort()
          }
          window.addEventListener('unload', unload)
          await workbook.xlsx.write(writer)
          writer.close()
          window.removeEventListener('unload', unload)
          await sleep(300)
        }
        if (lastAlert) {
          // setTimeout(async () => {
          await this.alert({ message: '다운로드가 완료되었습니다' })
          // }, 500)
        }
      } catch (error) {
        if (writer) {
          writer.abort()
        }
        throw error
      }
    },
    async s3PublicDownload (path, downloadName) {
      const evt = document.createElement('a')
      evt.href = `${process.env.VUE_APP_S3_STATIC_URL}/${path}`
      evt.download = downloadName
      document.body.appendChild(evt)
      evt.click()
      document.body.removeChild(evt)
    },
    async s3Download (key, download_name = '') {
      aws.config.update({
        region: process.env.VUE_APP_BUCKET_REGION,
        credentials: new aws.CognitoIdentityCredentials({
          IdentityPoolId: process.env.VUE_APP_IDENTITY_POOL_ID,
        }),
      })
      const s3 = new aws.S3()

      s3.getObject(
        {
          Bucket: process.env.VUE_APP_S3_UPLOAD_BUCKET,
          Key: key,
        },
        (err, data) => {
          if (err) {
            this.alert({
              title: 'S3 객체 다운로드 중 오류가 발생했습니다',
              message: err.message,
            })
          } else {
            const file_full_name = key.split('/').pop()
            const file_name = file_full_name.split('.').shift()
            const file_ext = file_full_name.split('.').pop()

            const blob = new Blob([data.Body], { type: data.ContentType })
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = (download_name || file_name) + '.' + file_ext
            link.click()
          }
        },
      )
    },
    async s3Upload (
      upload_file,
      is_temp = false,
      root_folder = 'crew',
      path = null,
      limit_size = null,
    ) {
      try {
        /**
         * AWS 설정
         */
        aws.config.update({
          region: process.env.VUE_APP_BUCKET_REGION,
          credentials: new aws.CognitoIdentityCredentials({
            IdentityPoolId: process.env.VUE_APP_IDENTITY_POOL_ID,
          }),
        })

        const s3 = new aws.S3({
          apiVersion: '2006-03-01',
          maxRetries: 0,
          httpOptions: {
            timeout: 120000,
            connectTimeout: 5000,
          },
          params: {
            Bucket: process.env.VUE_APP_S3_UPLOAD_BUCKET,
          },
        })

        /**
         * 기본 용량 제한 3Mb
         */
        if (!limit_size) limit_size = 1024 * 1024 * 3

        /**
         * 용량 제한 있을 시 체크
         */
        if (
          parseFloat(limit_size) > 0
          && parseFloat(upload_file.size) > parseFloat(limit_size)
        ) {
          throw (
            '허용 용량 초과 '
            + String(Math.round(upload_file.size / 1024 / 1024, 1))
            + 'Mb > '
            + String(Math.round(limit_size / 1024 / 1024, 1))
            + 'Mb '
          )
        }

        /**
         * 기본 경로 셋팅
         */
        const default_path = moment().format('YYYY/MM/DD')
        if (!path) path = default_path

        let upload_path
          = process.env.VUE_APP_MODE === 'production'
            ? 'production'
            : 'development/dev'
        if (is_temp === true) upload_path = 'production/temp'

        upload_path += '/' + root_folder + '/' + path

        /**
         * 업로드 URL 셋팅
         */
        let upload_url = process.env.VUE_APP_S3_STATIC_URL
        if (is_temp === true) upload_url += '/temp'
        else {
          upload_url += process.env.VUE_APP_MODE === 'production' ? '' : '/dev'
        }

        upload_url += '/' + root_folder + '/' + path

        /**
         * 파일 정보 셋팅
         */
        const timestamp = moment().valueOf()
        const file_name = upload_file.name
        const file_ext = upload_file.name.split('.').pop()
        const content_type = upload_file.type
        let temp_file_name = String(timestamp) + '_' + file_name
        temp_file_name = md5(temp_file_name) + '.' + file_ext

        /**
         * s3 업로드 파라미터 셋팅
         */
        const upload_key = upload_path + '/' + temp_file_name
        const params = {
          Key: upload_key,
          Body: upload_file,
          Bucket: process.env.VUE_APP_S3_UPLOAD_BUCKET,
          ContentType: content_type,
        }

        /**
         * s3 업로드 시작
         */
        await s3.putObject(params).promise()

        /**
         * 결과 리턴
         */
        return {
          file_name,
          real_name: temp_file_name,
          file_ext,
          file_size: upload_file.size,
          content_type,
          upload_path: upload_key,
          url: upload_url + '/' + temp_file_name,
        }
      } catch (error) {
        throw error
      }
    },
    // 파일 업로드 개선된 버젼 s3업로드 후에 common데이터베이스에 file 테이블에 기본적으로 데이터 쌓이고 해당 file_id값도 같이 리턴 되도록 개선
    async s3Upload2 (
      upload_file,
      is_temp = false,
      root_folder = 'crew',
      path = null,
      limit_size = null,
      file_idx = 0,
      is_priavte = true,
    ) {
      try {
        /**
         * Bucket 결정
         */
        let s3_bucket = process.env.VUE_APP_S3_UPLOAD_BUCKET
        if (is_priavte) {
          s3_bucket = process.env.VUE_APP_S3_PRIVATE_UPLOAD_BUCKET
        }

        /**
         * AWS 설정
         */
        aws.config.update({
          region: process.env.VUE_APP_BUCKET_REGION,
          credentials: new aws.CognitoIdentityCredentials({
            IdentityPoolId: process.env.VUE_APP_IDENTITY_POOL_ID,
          }),
        })

        const s3 = new aws.S3({
          apiVersion: '2006-03-01',
          maxRetries: 0,
          httpOptions: {
            timeout: 120000,
            connectTimeout: 5000,
          },
          params: {
            Bucket: s3_bucket,
          },
        })

        /**
         * 기본 용량 제한 3Mb
         */
        if (!limit_size) {
          limit_size = 1024 * 1024 * 3
        }

        /**
         * 용량 제한 있을 시 체크
         */
        if (
          parseFloat(limit_size) > 0
          && parseFloat(upload_file.size) > parseFloat(limit_size)
        ) {
          throw (
            '허용 용량 초과 '
            + String(Math.round(upload_file.size / 1024 / 1024, 1))
            + 'Mb > '
            + String(Math.round(limit_size / 1024 / 1024, 1))
            + 'Mb '
          )
        }

        /**
         * 기본 경로 셋팅
         */
        const default_path = moment().format('YYYY/MM/DD')
        if (!path) {
          path = default_path
        }

        let upload_path
          = process.env.VUE_APP_MODE === 'production'
            ? 'production'
            : 'development/dev'
        if (is_temp === true) {
          upload_path = 'production/temp'
        }

        upload_path += '/' + root_folder + '/' + path

        /**
         * 업로드 URL 셋팅
         */
        let upload_url = process.env.VUE_APP_S3_STATIC_URL
        if (is_temp === true) {
          upload_url += '/temp'
        } else {
          upload_url += process.env.VUE_APP_MODE === 'production' ? '' : '/dev'
        }

        upload_url += '/' + root_folder + '/' + path

        /**
         * priavte bucket URL, PATH 재 셋팅
         */
        if (is_priavte) {
          upload_url = process.env.VUE_APP_MODE
          upload_path
            = process.env.VUE_APP_MODE === 'local'
              ? 'development/dev'
              : process.env.VUE_APP_MODE

          if (is_temp === true) {
            upload_url += '/temp'
            upload_path += '/temp'
          }

          upload_path += '/' + root_folder + '/' + path
          upload_url += '/' + root_folder + '/' + path
        }

        /**
         * 파일 정보 셋팅
         */
        const timestamp = moment().valueOf()
        const file_name = upload_file.name
        const file_ext = upload_file.name.split('.').pop()
        const content_type = upload_file.type
        let temp_file_name = String(timestamp) + '_' + file_name
        temp_file_name = md5(temp_file_name) + '.' + file_ext

        /**
         * s3 업로드 파라미터 셋팅
         */
        const upload_key = upload_path + '/' + temp_file_name
        const params = {
          Key: upload_key,
          Body: upload_file,
          Bucket: s3_bucket,
          ContentType: content_type,
        }

        /**
         * s3 업로드 시작
         */
        await s3.putObject(params).promise()

        const commonFilePayload = {
          serviceType: 'officeb-master-admin',
          displayFileName: file_name,
          fileName: temp_file_name,
          filePath: upload_key,
          awsS3Url: upload_url + '/' + temp_file_name,
          fileFormatType: content_type,
          fileExtension: file_ext,
          fileSize: upload_file.size,
          displayOrder: file_idx + 1,
          registrationAdminType: 'crew-master',
          registrationAdminLoginId: '임시고정',
        }

        /**
         * common.file에 파일 업로드 후 file id값 추출
         */
        const response = await this.post(
          `${this.SPARK_ONE_CREW_API_URL}common/file/post`,
          commonFilePayload,
        )

        /**
         * 결과 리턴
         */
        return {
          fileName: file_name,
          realName: temp_file_name,
          fileExt: file_ext,
          fileSize: upload_file.size,
          contentType: content_type,
          uploadPath: upload_key,
          url: upload_url + '/' + temp_file_name,
          commonFileId: response.data,
        }
      } catch (error) {
        throw error
      }
    },
    async fileDownload (response, fileName = '') {
      try {
        // 파일명이 없을경우 기본 이름으로 설정
        if (fileName == '') {
          fileName = response.data.fileName
        }
        const fileResponse = await fetch(response.data.fileUrl)
        const writableStream = streamSaver.createWriteStream(fileName, {
          size: fileResponse.headers.get('Content-Length'),
        })

        const readableStream = fileResponse.body
        const writer = readableStream.pipeTo(writableStream)

        await writer
      } catch (e) {
        await this.alert({
          message: '파일 다운로드를 실패하였습니다.',
        })
      }
    },
    async fileDowloadURI (url) {
      const link = document.createElement('a')
      link.setAttribute('href', url)
      link.style.display = 'none'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    async commonFileUpload (
      file,
      baseDir = 'crew',
      bucketType = 'public',
      displayOrder = 0,
    ) {
      let bucketBase = 'development/dev'
      if (process.env.VUE_APP_MODE === 'production') {
        bucketBase = 'production'
      }

      const date = new Date()
      const datePath = `${date.getFullYear()}/${String(
        date.getMonth() + 1,
      ).padStart(2, '0')}/${String(date.getDate()).padStart(2, '0')}`

      const displayFileName = file.name
      const fileSize = file.size
      const fileExtension = file.name.split('.').pop()
      const contentType = file.type
      const uploadFileName = `${this.uuidv4()}.${fileExtension}`
      const uploadPath = `${bucketBase}/${baseDir}/${datePath}/${uploadFileName}`
      const awsS3Url = `${process.env.VUE_APP_S3_STATIC_URL}/${uploadPath
        .split('/')
        .splice(1)
        .join('/')}`

      let s3Bucket = process.env.VUE_APP_S3_UPLOAD_BUCKET
      if (bucketType == 'private') {
        s3Bucket = process.env.VUE_APP_S3_PRIVATE_UPLOAD_BUCKET
      }
      aws.config.update({
        region: process.env.VUE_APP_BUCKET_REGION,
        credentials: new aws.CognitoIdentityCredentials({
          IdentityPoolId: process.env.VUE_APP_IDENTITY_POOL_ID,
        }),
      })
      const s3 = new aws.S3({
        apiVersion: '2006-03-01',
        params: { Bucket: s3Bucket },
      })
      try {
        store.commit('increaseRequestCount')
        await s3
          .upload({
            Key: uploadPath,
            Body: file,
            ContentType: contentType,
          })
          .promise()

        const fileId = await this.post(
          `${this.SPARK_ONE_CREW_API_URL}common/file/post`,
          {
            serviceType: 'officeb-master-admin',
            displayFileName,
            fileName: uploadFileName,
            filePath: uploadPath,
            awsS3Url,
            fileFormatType: contentType,
            fileExtension,
            fileSize,
            displayOrder: displayOrder + 1,
          },
        )
        return {
          displayFileName,
          uploadFileName,
          fileExtension,
          fileSize,
          contentType,
          uploadPath,
          awsS3Url,
          fileId: fileId.data,
        }
      } finally {
        store.commit('decreaseRequestCount')
      }
    },
  },
})
