<template>
<div class="inputbox" :class="{ disabled: attrs.disabled }">
  <label :for="uuid">
    <input
    type="radio"
    :id="uuid"
    :value="value"
    :checked="isChecked"
    @change="change"
    v-bind="attrs" />
    <span class="radio"></span>
  </label>
</div>
</template>

<script>
export default {
  name: 'SpRadio',
  inheritAttrs: false,
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    value: { default: undefined },
    id: { type: String, default: '' },
    checked: { required: false },
  },
  data () {
    return {
      uuid: null,
    }
  },
  created () {
    this.uuid = this.id
    if (this.uuid == '') {
      this.uuid = this.uuidv4()
    }
  },
  methods: {
    change (event) {
      this.$emit('change', event.target.value)
    },
  },
  computed: {
    attrs () {
      return this.$attrs
    },
    isChecked () {
      return this.checked == this.value
    },
  },
}
</script>
<style lang="scss">
.inputbox:has(:disabled) + label {
  opacity: 0.6;
}
</style>

<style lang="scss" scoped>
.inputbox {
  margin: auto;
  user-select: none;
  margin: 0 4px;
  position: relative;
  border-radius: var(--theme-input-radius);
  padding: 1px;
  // display: inline-block;
  display: inline;
  &:first-child {
    // margin-left: 0;
  }
}
.radio {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #fff;
  background-image: linear-gradient(#f0f0f0, #fff);
  box-shadow: inset 0 1px 1px rgba(255,255,255,0.35), inset 0 -1px 1px rgba(0,0,0,0.35);
}
.radio:after {
  content: "";
  position: absolute;
  display: block;
  top: 6px;
  left: 6px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #fff;
  opacity: 0;
}
.inputbox label {
  display: inline-block;
  cursor: pointer;
  height: auto !important;
  width: auto;
  line-height: initial !important;
  &:has(:disabled) {
    cursor: not-allowed;
  }
}
.inputbox input[type="radio"] {
  width: 1px;
  height: 1px;
  // margin: 0 4px;
  margin: 0;
  // padding: 4px 2px;
  padding: 4px 8px;
  border: 0;
  outline: none;
  box-sizing: content-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  opacity: 0;
  &:focus+span:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    border-color: var(--theme-primary-color);
    animation: shadow 0.1s ease-in-out forwards;
  }
}
@keyframes shadow {
  to {
    box-shadow: 0 0 6px var(--theme-primary-color);
  }
}
.inputbox input[type="radio"]:checked + .radio {
  background-color: var(--theme-primary-color);
  // background-image: linear-gradient(var(--theme-primary-color), #2041fbbf);
  background-image: linear-gradient(var(--theme-primary-color), #38383abf);
}
.inputbox input[type="radio"]:checked:disabled + .radio {
  background-color: #ced5da;
  background-image: linear-gradient(#777777ea, #99999992);
}
.inputbox input[type="radio"]:checked + .radio:after {
  opacity: 1;
  transform: scale(1);
  transition: all 0.15s ease;
}
</style>
