<template>
  <span class="badge">
    <slot></slot>
  </span>
</template>
<script>
export default {
  name: 'SpBadge',
  props: {
  },
  computed: {
  },
}
</script>
<style lang="scss" scoped>
.badge {
  display: inline-block;
  text-align: center;
  position: relative;
  box-sizing: border-box;
  min-width: 60px;
  overflow:hidden;
  white-space:nowrap;
  text-overflow:ellipsis;
  margin: 4px 2.5px;
  text-indent: 0;
  padding: 0 5px;
  vertical-align: top;
  border-radius: var(--theme-input-radius);
  background-color: var(--theme-background-color);
  border-width: 1px;
  border-style: solid;
  border-color: var(--theme-primary-color);
  color: var(--theme-primary-color);
  font: 600 14px "Pretendard Variable", Pretendard;
  line-height: 24px;
  // box-shadow: 0 0 0 4px rgba(255,255,255,.1) inset;
  // &.outline {
    // box-shadow: 0 0 0 4px rgba(255,255,255,.9) inset;
  // }
  &.primary {
    background-color: var(--theme-primary-color);
    border-color: var(--theme-primary-color);
    color: var(--theme-background-color);

    &.outline {
      border-color: var(--theme-primary-color);
      background-color: var(--theme-background-color);
      color: var(--theme-primary-color);
    }
  }

  &.secondary {
    background-color: var(--theme-secondary-color);
    border-color: var(--theme-secondary-color);
    color: var(--theme-background-color);

    &.outline {
      border-color: var(--theme-secondary-color);
      background-color: var(--theme-background-color);
      color: var(--theme-secondary-color);
    }
  }

  &.tertiary {
      background-color: var(--theme-tertiary-color);
      border-color: var(--theme-tertiary-color);
      color: var(--theme-background-color);

    &.outline {
      border-color: var(--theme-tertiary-color);
      background-color: var(--theme-background-color);
      color: var(--theme-tertiary-color);
    }
  }

  &.success {
      background-color: var(--theme-success-color);
      border-color: var(--theme-success-color);
      color: var(--theme-background-color);

    &.outline {
      border-color: var(--theme-success-color);
      background-color: var(--theme-background-color);
      color: var(--theme-success-color);
    }
  }

  &.danger {
      background-color: var(--theme-danger-color);
      border-color: var(--theme-danger-color);
      color: var(--theme-background-color);

    &.outline {
      border-color: var(--theme-danger-color);
      background-color: var(--theme-background-color);
      color: var(--theme-danger-color);
    }
  }

  &.warning {
      background-color: var(--theme-warning-color);
      border-color: var(--theme-warning-color);
      color: var(--theme-background-color);

    &.outline {
      border-color: var(--theme-warning-color);
      background-color: var(--theme-background-color);
      color: var(--theme-warning-color);
    }
  }

  &.info {
      background-color: var(--theme-info-color);
      border-color: var(--theme-info-color);
      color: var(--theme-background-color);

    &.outline {
      border-color: var(--theme-info-color);
      background-color: var(--theme-background-color);
      color: var(--theme-info-color);
    }
  }

  &.light {
      background-color: var(--theme-light-color);
      border-color: var(--theme-light-color);
      color: var(--theme-background-color);
    // }

    &.outline {
      border-color: var(--theme-light-color);
      background-color: var(--theme-background-color);
      color: var(--theme-light-color);
    }
  }

  &.dark {
      background-color: var(--theme-dark-color);
      border-color: var(--theme-dark-color);
      color: var(--theme-background-color);

    &.outline {
      border-color: var(--theme-dark-color);
      background-color: var(--theme-background-color);
      color: var(--theme-dark-color);
    }
  }

  &.red {
      color: var(--theme-background-color);
      border-color:  red;
      background-color: red;

    &.outline {
      color: red;
      border-color:  red;
      background-color: var(--theme-background-color);
    }
  }

  &.black {
      color: var(--theme-background-color);
      border-color:  #000;
      background-color: #000;

    &.outline {
      color: #000;
      border-color:  #000;
      background-color: var(--theme-background-color);
    }
  }
}
</style>
