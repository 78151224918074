import Vue from 'vue'
import store from '../store'
import VueRouter from 'vue-router'
import productRoutes from '@/router/productRoutes'
import spaceRoutes from '@/router/spaceRoutes'
import accountRoutes from '@/router/accountRoutes'
import usageRoutes from '@/router/usageRoutes'
import settlementRoutes from '@/router/settlementRoutes'

const ClientInformationList = () => import('@/components/client/information/ClientInformationList')
const ClientInformationDetail = () => import('@/components/client/information/ClientInformationDetail')
const ClientInformationRegist = () => import('@/components/client/information/ClientInformationRegist')
const ClientInformationModify = () => import('@/components/client/information/ClientInformationModify')
const ClientAdminList = () => import('@/components/client/admin/ClientAdminList')
const ClientMemberList = () => import('@/components/client/member/ClientMemberList')
const ClientMemberDetail = () => import('@/components/client/member/ClientMemberDetail')
const ClientMemberModify = () => import('@/components/client/member/ClientMemberModify')
const ClientCompanyList = () => import('@/components/client/company/ClientCompanyList')
const ClientCompanyDetail = () => import('@/components/client/company/ClientCompanyDetail')
const CrewInformationList = () => import('@/components/crew/information/CrewInformationList')
const CrewInformationDetail = () => import('@/components/crew/information/CrewInformationDetail')
const CrewInformationRegist = () => import('@/components/crew/information/CrewInformationRegist')
const CrewInformationModify = () => import('@/components/crew/information/CrewInformationModify')
const CrewPermissionList = () => import('@/components/crew/permission/CrewPermissionList')
const CrewPermissionDetail = () => import('@/components/crew/permission/CrewPermissionDetail')
const CrewPermissionRegist = () => import('@/components/crew/permission/CrewPermissionRegist')
const CrewPermissionModify = () => import('@/components/crew/permission/CrewPermissionModify')
const CrewDepartmentPosition = () => import('@/components/crew/department/CrewDepartmentPosition')

// const AccountManagement = () => import('@/components/account/AccountManagement')
// const AccountRegistration = () => import('@/components/account/AccountRegistration')
// const AccountModification = () => import('@/components/account/AccountModification')
const AccountTenantList = () => import('@/components/account/tenant/List')
const AccountTenantForm = () => import('@/components/account/tenant/Form')
const managerList = () => import('@/components/manager/List')
const managerForm = () => import('@/components/manager/Form')
const managerFormMyOfficeB = () => import('@/components/manager/FormMyOfficeB')
const CommonBannerList = () => import('@/components/system/banner/List')
const CommonBannerForm = () => import('@/components/system/banner/Form')
const CommonBannerGroupList = () => import('@/components/system/banner/GroupList')
const CommonPopupList = () => import('@/components/popup/List')
const CommonPopupForm = () => import('@/components/popup/Form')
// const MenuAuthGroupManagement = () => import('@/components/system/MenuAuthGroupManagement')
// const MenuAuthGroupUpdate = () => import('@/components/system/MenuAuthGroupUpdate')
// const MenuAuthGroupCreate = () => import('@/components/system/MenuAuthGroupCreate')
const Error404 = () => import('@/components/common/error/Error404')
const Error403 = () => import('@/components/common/error/Error403')
const Main = () => import('@/components/Main')
const Login = () => import('@/components/login/Login')

const OperatorManagement = () => import('@/components/operator/OperatorManagement')
const OperatorRegistration = () => import('@/components/operator/OperatorRegistration')
const OperatorModification = () => import('@/components/operator/OperatorModification')
// const MemberManagement = () => import('@/components/member/MemberManagement')
// const MemberModification = () => import('@/components/member/MemberModification')
const UserInfo = () => import('@/components/user/UserInfoListPage')
const UserInfoEditPage = () => import('@/components/user/UserInfoEditPage')
const UserWithdrawalList = () => import('@/components/user/UserWithdrawalList')

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    name: 'Error404',
    component: Error404,
    meta: {
      requiresAuth: false,
      fullScreen: true,
    },
  },
  {
    path: '*',
    name: 'Error403',
    component: Error403,
    meta: {
      requiresAuth: false,
      fullScreen: true,
    },
  },
  {
    path: '/',
    name: 'Main',
    component: Main,
    meta: {
      requiresAuth: false,
      fullScreen: false,
    },
    beforeEnter: async (to, from, next) => {
      if (!isLogined()) {
        // 로그인 페이지로 리다이렉트
        next({
          path: '/login',
          query: { redirect: to.fullPath },
        })
      } else {
        next()
      }
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      requiresAuth: false,
      fullScreen: true,
      mode: 'login',
      redirect: '/',
    },
  },
  {
    path: '/login/reset/:token',
    name: 'LoginNewPassword',
    component: Login,
    meta: {
      requiresAuth: false,
      fullScreen: true,
      mode: 'newPassword',
      redirect: '/',
    },
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Login,
    meta: {
      requiresAuth: false,
      fullScreen: true,
    },
    beforeEnter: async (to, from, next) => {
      let result
      if (await store.dispatch('user/refresh')) {
        result = await store.dispatch('user/logout')
      }
      if (result == 'error') {
        alert('로그아웃에 실패 했습니다 인터넷 연결을 확인 해 주세요')
        next(from.fullPath)
      } else {
        if (to.query.returnPage) {
          next({
            path: '/login',
            query: { redirect: from.fullPath },
          })
        } else {
          next('/login')
        }
      }
    },
  },
  {
    path: '/client/information',
    name: 'ClientInformationList',
    component: ClientInformationList,
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/client/information/create',
    name: 'ClientInformationRegist',
    component: ClientInformationRegist,
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/client/information/:id',
    name: 'ClientInformationDetail',
    component: ClientInformationDetail,
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/client/information/:id/edit',
    name: 'ClientInformationModify',
    component: ClientInformationModify,
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/client/admin',
    name: 'ClientAdminList',
    component: ClientAdminList,
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/client/member',
    name: 'ClientMemberList',
    component: ClientMemberList,
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/client/member/:id',
    name: 'ClientMemberDetail',
    component: ClientMemberDetail,
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/client/member/:id/edit',
    name: 'ClientMemberModify',
    component: ClientMemberModify,
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/client/company',
    name: 'ClientCompanyList',
    component: ClientCompanyList,
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/client/company/:id',
    name: 'ClientCompanyDetail',
    component: ClientCompanyDetail,
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/crew/information',
    name: 'CrewInformationList',
    component: CrewInformationList,
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/crew/information/create',
    name: 'CrewInformationRegist',
    component: CrewInformationRegist,
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/crew/information/:id',
    name: 'CrewInformationDetail',
    component: CrewInformationDetail,
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/crew/information/:id/edit',
    name: 'CrewInformationModify',
    component: CrewInformationModify,
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/crew/permission',
    name: 'CrewPermissionList',
    component: CrewPermissionList,
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/crew/permission/create',
    name: 'CrewPermissionRegist',
    component: CrewPermissionRegist,
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/crew/permission/:id',
    name: 'CrewPermissionDetail',
    component: CrewPermissionDetail,
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/crew/permission/:id/edit',
    name: 'CrewPermissionModify',
    component: CrewPermissionModify,
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },

  // 관리자 > 부서/직책 정보
  {
    path: '/crew/department-position',
    name: 'CrewDepartmentPosition',
    component: CrewDepartmentPosition,
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/account/tenant-business',
    name: 'AccountTenantBusinessList',
    component: AccountTenantList,
    meta: {
      mode: 'business',
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/account/tenant-business/create',
    name: 'AccountTenantBusinessCreate',
    component: AccountTenantForm,
    meta: {
      mode: 'business',
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/account/tenant-business/:id/edit',
    name: 'AccountTenantBusinessEdit',
    component: AccountTenantForm,
    meta: {
      mode: 'business',
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/account/tenant-business/manager/create',
    name: 'ManagerTenantBusinessCreate',
    component: managerFormMyOfficeB,
    meta: {
      mode: 'tenant-manager',
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/account/tenant-business/manager/:id/edit',
    name: 'ManagerTenantBusinessEdit',
    component: managerForm,
    meta: {
      mode: 'tenant-manager',
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/account/tenant-personal',
    name: 'AccountTenantPersonalList',
    component: AccountTenantList,
    meta: {
      mode: 'personal',
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/account/tenant-personal/create',
    name: 'AccountTenantPersonalCreate',
    component: AccountTenantForm,
    meta: {
      mode: 'personal',
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/account/tenant-personal/:id/edit',
    name: 'AccountTenantPersonalForm',
    component: AccountTenantForm,
    meta: {
      mode: 'personal',
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/account/tenant-personal/manager/create',
    name: 'AccountTenantPersonalManagerCreate',
    component: managerForm,
    meta: {
      mode: 'tenant-manager',
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/account/tenant-personal/manager/:id/edit',
    name: 'AccountTenantPersonalManagerEdit',
    component: managerForm,
    meta: {
      mode: 'tenant-manager',
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/account/membership-manager',
    name: 'membershipManagerList',
    component: managerList,
    meta: {
      mode: 'membership-manager',
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/account/membership-manager/create',
    name: 'membershipManagerCreate',
    component: managerForm,
    meta: {
      mode: 'membership-manager',
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/account/membership-manager/:id/edit',
    name: 'membershipManagerEdit',
    component: managerForm,
    meta: {
      mode: 'membership-manager',
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/account/tenant-manager',
    name: 'tenantManagerList',
    component: managerList,
    meta: {
      mode: 'tenant-manager',
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/account/tenant-manager/create',
    name: 'tenantManagerCreate',
    component: managerForm,
    meta: {
      mode: 'tenant-manager',
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/account/tenant-manager/:id/edit',
    name: 'tenantManagerEdit',
    component: managerForm,
    meta: {
      mode: 'tenant-manager',
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/system/router-list',
    name: 'MenuRouterList',
    component: () => import('@/components/system/MenuRouterList'),
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/operator',
    name: 'OperatorManagement',
    component: OperatorManagement,
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/operator/registration',
    name: 'OperatorRegistration',
    component: OperatorRegistration,
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/operator/modification/:id',
    name: 'OperatorModification',
    component: OperatorModification,
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/contents/board',
    name: 'BoardList',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/contents/board/List'),
  },
  {
    path: '/contents/board/create',
    name: 'BoardRegist',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/contents/board/Form'),
  },
  {
    path: '/contents/board/:id/edit',
    name: 'BoardModify',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/contents/board/Form'),
  },
  {
    path: '/contents/board/article-type/:boardId',
    name: 'BoardArticleTypeList',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/contents/article_type/List'),
  },
  {
    path: '/contents/board/article-type/:boardId/create',
    name: 'BoardArticleTypeRegist',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/contents/article_type/Form'),
  },
  {
    path: '/contents/board/article-type/:boardId/:infoKey/edit',
    name: 'BoardArticleTypeModify',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/contents/article_type/Form'),
  },
  {
    path: '/contents/article',
    name: 'ArticleList',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/contents/article/List'),
  },
  {
    path: '/contents/article/create',
    name: 'ArticleRegist',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/contents/article/Form'),
  },
  {
    path: '/contents/article/:id/edit',
    name: 'ArticleModify',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/contents/article/Form'),
  },
  {
    path: '/operator/notice',
    name: 'NoticeList',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/operator/notice/List'),
  },
  {
    path: '/operator/notice/create',
    name: 'NoticeRegist',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/operator/notice/Form'),
  },
  {
    path: '/operator/notice/:id/edit',
    name: 'NoticeModify',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/operator/notice/Form'),
  },
  {
    path: '/operator/branch-notice',
    name: 'BranchNoticeList',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/operator/branch_notice/List'),
  },
  {
    path: '/operator/branch-notice/create',
    name: 'BranchNoticeRegist',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/operator/branch_notice/Form'),
  },
  {
    path: '/operator/branch-notice/:id/edit',
    name: 'BranchNoticeModify',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/operator/branch_notice/Form'),
  },
  {
    path: '/operator/blog',
    name: 'BlogList',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/operator/blog/List'),
  },
  {
    path: '/operator/blog/create',
    name: 'BlogRegist',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/operator/blog/Form'),
  },
  {
    path: '/operator/blog/:id/edit',
    name: 'BlogModify',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/operator/blog/Form'),
  },
  {
    path: '/operator/faq',
    name: 'FaqList',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/operator/faq/List'),
  },
  {
    path: '/operator/faq/create',
    name: 'FaqRegist',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/operator/faq/Form'),
  },
  {
    path: '/operator/faq/:id/edit',
    name: 'FaqModify',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/operator/faq/Form'),
  },
  {
    path: '/operator/terms',
    name: 'TermsList',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/operator/terms/List'),
  },
  {
    path: '/operator/terms/create',
    name: 'TermsRegist',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/operator/terms/Form'),
  },
  {
    path: '/operator/terms/:id/edit',
    name: 'TermsModify',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/operator/terms/Form'),
  },
  {
    path: '/common/tag',
    name: 'TagList',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/system/tag/List'),
  },
  {
    path: '/common/tag/create',
    name: 'TagRegist',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/system/tag/Form'),
  },
  {
    path: '/common/tag/:tagName/edit',
    name: 'TagModify',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/system/tag/Form'),
  },
  {
    path: '/common/category',
    name: 'CategoryList',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/system/category/List'),
  },
  {
    path: '/common/category/create',
    name: 'CategoryRegist',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/system/category/Form'),
  },
  {
    path: '/common/category/:id/edit',
    name: 'CommonCategoryModify',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/system/category/Form'),
  },
  {
    path: '/common/db-cols-code',
    name: 'DbColsCodeList',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/system/db_cols_code/List'),
  },
  {
    path: '/common/db-cols-code/create',
    name: 'DbColsCodeRegist',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/system/db_cols_code/Form'),
  },
  {
    path: '/common/db-cols-code/:id/edit',
    name: 'DbColsCodeModify',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/system/db_cols_code/Form'),
  },

  // 회원 > 회원정보
  {
    path: '/user/information',
    name: 'UserInfo',
    component: UserInfo,
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  // 회원 > 회원정보 > 회원정보 상세
  {
    path: '/user/information/:id/edit',
    name: 'UserInfoEdit',
    component: UserInfoEditPage,
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  // 회원 > 이용정지 회원
  {
    path: '/user/suspended-management',
    name: 'UserInfoSuspended',
    component: UserInfo,
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  // 회원 > 탈퇴
  {
    path: '/user/withdrawal',
    name: 'UserWithdrawalList',
    component: UserWithdrawalList,
    meta: {
      requiresAuth: false,
      fullScreen: false,
    },
  },
  {
    path: '/myofficeb/notice',
    name: 'MyOfficeBNoticeList',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/myofficeb/notice/List'),
  },
  {
    path: '/myofficeb/notice/write',
    name: 'MyOfficeBNoticeWrite',
    meta: {
      requiresAuth: true,
      fullScreen: false,
      pageTitle: '공지 등록',
    },
    component: () => import('@/components/myofficeb/notice/Write'),
  },
  {
    path: '/myofficeb/notice/:id/edit',
    name: 'MyOfficeBNoticeEdit',
    meta: {
      requiresAuth: true,
      fullScreen: false,
      pageTitle: '공지 수정',
    },
    component: () => import('@/components/myofficeb/notice/Edit'),
  },
  {
    path: '/myofficeb/workorder',
    name: 'MyOfficeBWorkorderList',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/myofficeb/workorder/List'),
  },
  {
    path: '/myofficeb/workorder/:id',
    name: 'MyOfficeBWorkorderDetail',
    meta: {
      requiresAuth: true,
      fullScreen: false,
      pageTitle: '워크오더 상세',
    },
    component: () => import('@/components/myofficeb/workorder/Detail'),
  },
  // {
  //   path: '/member',
  //   name: 'MemberManagement',
  //   component: MemberManagement,
  //   meta: {
  //     requiresAuth: false,
  //     fullScreen: false,
  //   },
  // },
  // {
  //   path: '/member/modification/:id',
  //   name: 'MemberModification',
  //   component: MemberModification,
  //   meta: {
  //     requiresAuth: true,
  //     fullScreen: false,
  //   },
  // },
  {
    path: '/wallpad/meetingRoomList',
    name: 'WallpadMeetingRoomList',
    meta: {
      requiresAuth: false,
      fullScreen: true,
    },
    component: () => import('@/components/wallpad/WallpadMeetingRoomList'),
    beforeEnter: async (to, from, next) => {
      // 로그인이 안되어 있거나 월패드 로그인도 없을 경우
      if (!isLogined() && !window.localStorage.getItem('wallpad_logined')) {
        // 로그인 페이지로 리다이렉트
        next({
          path: '/login',
          query: { redirect: to.fullPath },
        })
        // 미팅룸 선택이 완료된 상태(토큰 존재)인 경우 (PWA 앱 끄고 킬 경우 페이지 이동 처리)
      } else if (window.localStorage.getItem('wallpad_token')) {
        next('/wallpad/status')
      } else {
        next()
      }
    },
  },
  {
    path: '/wallpad/status',
    name: 'WallpadStatus',
    meta: {
      requiresAuth: false,
      fullScreen: true,
    },
    component: () => import('@/components/wallpad/WallpadStatus'),
    beforeEnter: async (to, from, next) => {
      // 로그인이 안되어 있거나 월패드 로그인도 없을 경우
      if (
        (!isLogined() && !window.localStorage.getItem('wallpad_logined'))
        || !window.localStorage.getItem('wallpad_token')
      ) {
        // 로그인 이후 페이지 접근을 위해 미팅룸 선택페이지로 이동
        next('/wallpad/meetingRoomList')
      } else {
        next()
      }
    },
  },
  {
    path: '/corporate/service', // 기업서비스 관리 리스트
    name: 'CorporateServiceList',
    meta: {
      requiresAuth: true, // 일괄 수정 예정
      fullScreen: false,
    },
    component: () => import('@/components/corporate/service/List'),
  },
  {
    path: '/corporate/service/create', // 기업서비스 컨텐츠 등록
    name: 'CorporateServiceCreate',
    meta: {
      requiresAuth: true, // 일괄 수정 예정
      fullScreen: false,
    },
    component: () => import('@/components/corporate/service/Form'),
  },
  {
    path: '/corporate/service/:id/edit', // 기업서비스 컨텐츠 수정
    name: 'CorporateServiceModify',
    meta: {
      requiresAuth: true, // 일괄 수정 예정
      fullScreen: false,
    },
    component: () => import('@/components/corporate/service/Form'),
  },
  {
    path: '/corporate/banner', // 기업서비스 메인 배너 관리
    name: 'CorporateServiceBannerList',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/corporate/service/BannerList'),
  },
  {
    path: '/corporate/banner/create', // 기업서비스 메인 배너 관리
    name: 'CorporateServiceBannerForm',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: CommonBannerForm,
  },
  {
    path: '/corporate/apply', // 기업서비스 신청 내역
    name: 'CorporateServiceApplyList',
    meta: {
      requiresAuth: true, // 일괄 수정 예정
      fullScreen: false,
    },
    component: () => import('@/components/corporate/service/ApplyList'),
  },
  {
    path: '/corporate/category', // 기업서비스 카테고리 리스트
    name: 'CorporateCategoryList',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/corporate/category/List'),
  },
  {
    path: '/corporate/category/create', // 기업서비스 카테고리 등록
    name: 'CorporateCategoryForm',
    meta: {
      requiresAuth: true, // 일괄 수정 예정
      fullScreen: false,
    },
    component: () => import('@/components/system/category/Form'),
  },
  {
    path: '/corporate/category/:id/edit',
    name: 'CategoryModify',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/system/category/Form'),
  },
  {
    path: '/corporate/category/Form', // 기업서비스 카테고리 관리 > 카테고리 배너 등록 리스트
    name: 'CorporateCategoryBannerForm',
    meta: {
      requiresAuth: true, // 일괄 수정 예정
      fullScreen: false,
    },
    component: () => import('@/components/corporate/category/Form'),
  },
  {
    path: '/corporate/category/banner', // 기업서비스 카테고리 관리 > 카테고리 배너 등록
    name: 'CorporateCategoryCreateBannerForm',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: CommonBannerForm,
  },
  {
    path: '/common/banner', // 공통 배너 리스트
    name: 'CommonBannerList',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: CommonBannerList,
  },
  {
    path: '/common/banner/create', // 공통 배너 생성
    name: 'CommonBannerForm',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: CommonBannerForm,
  },
  {
    path: '/common/app-popup', // 앱 파업 리스트
    name: 'CommonAppPopupList',
    meta: {
      mode: 'app-popup',
      requiresAuth: true,
      fullScreen: false,
    },

    component: CommonPopupList,
  },
  {
    path: '/common/app-popup/create', // 앱 팝업 생성
    name: 'CommonAppPopupForm',
    meta: {
      mode: 'app-popup',
      requiresAuth: true,
      fullScreen: false,
    },
    component: CommonPopupForm,
  },
  {
    path: '/common/web-popup', // 웹 파업 리스트
    name: 'CommonWebPopupList',
    meta: {
      mode: 'web-popup',
      requiresAuth: true,
      fullScreen: false,
    },
    component: CommonPopupList,
  },
  {
    path: '/common/web-popup/create', // 웹 팝업 생성
    name: 'CommonWebPopupForm',
    meta: {
      mode: 'web-popup',
      requiresAuth: true,
      fullScreen: false,
    },
    component: CommonPopupForm,
  },
  {
    path: '/common/group/banner', // 배너 그룹 설정
    name: 'CommonBannerGroupList',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: CommonBannerGroupList,
  },
  ...productRoutes,
  ...spaceRoutes,
  ...accountRoutes,
  ...usageRoutes,
  ...settlementRoutes,
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

// 엑세스 토큰 보유여부 확인
const isLogined = function () {
  return !!store.state.user.accessToken
}

// 토큰 내 메뉴 권한 확인 ( accessToken:auth:r 권한에 해당 메뉴 번호가 포함 되었는지 확인)
const isReadable = function (to) {
  // 비로그인 단독 호출시 버그 방지 (return 권한 없음)
  if (!isLogined()) return false
  const menuId = store.getters.pathToMenu(to.path)?.menuNo
  return store.getters['user/accessibleMenus'].includes(menuId)
}

// 네비게이션 가드
router.beforeEach(async (to, from, next) => {
  // 진행중이던 axios 모두 취소
  store.dispatch('cancelPendingRequests')
  // 로그인이 필요한 페이지여부 확인
  if (to.matched.some(route => route.meta.requiresAuth)) {
    // 로그인 여부 확인
    if (isLogined()) {
      // 페이지 접근 권한 확인
      if (isReadable(to)) {
        // 권한 있음
        next()
      } else {
        // 권한 없음 403
        next({ name: 'Error403', params: [to.path] })
      }
    } else {
      // 엑세스 토큰 없음, 리프레시 토큰 갱신 시도
      if (await store.dispatch('user/refresh')) {
        // 리프레시 시도 성공
        // 페이지 접근 권한 확인
        if (isReadable(to)) {
          // 권한 있음
          next()
        } else {
          // 권한 없음
          next({ name: 'Error403', params: [to.path] })
        }
      // 리프레시 실패 (토큰 없음 or 만료 or 인증서버 로직 에러)
      } else {
        // 로그인 페이지로 리다이렉트
        next({
          path: '/login',
          query: { redirect: to.fullPath },
        })
      }
    }
  // 권한이 필요 없는 페이지
  } else {
    // 이동
    await store.dispatch('user/refresh') // 리프레시까지는 말고 메뉴 갱신 api 만 해도 되는데 일단 급하니까 그냥 주석 해제
    next()
  }
})

export default router
