<template>
<div class="gap">
  <button
  class="button"
  ref="button"
  :id="uuid"
  v-on="lisnters"
  v-bind="attrs">
    <slot></slot>
  </button>
</div>
</template>

<script>
export default {
  name: 'SpButton',
  inheritAttrs: false,

  props: {
    id: { type: String, default: '' },
  },
  data () {
    return {
      uuid: null,
    }
  },
  created () {
    this.uuid = this.id
    if (this.uuid == '') {
      this.uuid = this.uuidv4()
    }
  },
  mounted () {

  },
  methods: {
    focus () {
      this.$refs.button.focus()
    },

  },
  computed: {
    lisnters () {
      const { input, ...listeners } = this.$listeners
      return listeners
    },
    attrs () {
      return this.$attrs
    },
  },
}
</script>

<style lang="scss" scoped>
.gap {
  max-width: 100%;
  position: relative;
  display: inline-block;
  min-width: 60px;
  box-sizing: border-box;

  &.primary {
    .button {
      background-color: var(--theme-primary-color);
      border: 1px solid var(--theme-primary-color);
      color: var(--theme-background-color);
    }

    &.outline .button {
      border: 1px solid var(--theme-primary-color);
      background-color: var(--theme-background-color);
      color: var(--theme-primary-color);
    }
  }

  &.secondary {
    .button {
      background-color: var(--theme-secondary-color);
      border: 1px solid var(--theme-secondary-color);
      color: var(--theme-background-color);
    }

    &.outline .button {
      border: 1px solid var(--theme-secondary-color);
      background-color: var(--theme-background-color);
      color: var(--theme-secondary-color);
    }
  }

  &.tertiary {
    .button {
      background-color: var(--theme-tertiary-color);
      border: 1px solid var(--theme-tertiary-color);
      color: var(--theme-background-color);
    }

    &.outline .button {
      border: 1px solid var(--theme-tertiary-color);
      background-color: var(--theme-background-color);
      color: var(--theme-tertiary-color);
    }
  }

  &.success {
    .button {
      background-color: var(--theme-success-color);
      border: 1px solid var(--theme-success-color);
      color: var(--theme-background-color);
    }

    &.outline .button {
      border: 1px solid var(--theme-success-color);
      background-color: var(--theme-background-color);
      color: var(--theme-success-color);
    }
  }

  &.danger {
    .button {
      background-color: var(--theme-danger-color);
      border: 1px solid var(--theme-danger-color);
      color: var(--theme-background-color);
    }

    &.outline .button {
      border: 1px solid var(--theme-danger-color);
      background-color: var(--theme-background-color);
      color: var(--theme-danger-color);
    }
  }

  &.warning {
    .button {
      background-color: var(--theme-warning-color);
      border: 1px solid var(--theme-warning-color);
      color: var(--theme-background-color);
    }

    &.outline .button {
      border: 1px solid var(--theme-warning-color);
      background-color: var(--theme-background-color);
      color: var(--theme-warning-color);
    }
  }

  &.info {
    .button {
      background-color: var(--theme-info-color);
      border: 1px solid var(--theme-info-color);
      color: var(--theme-background-color);
    }

    &.outline .button {
      border: 1px solid var(--theme-info-color);
      background-color: var(--theme-background-color);
      color: var(--theme-info-color);
    }
  }

  &.light {
    .button {
      background-color: var(--theme-light-color);
      border: 1px solid var(--theme-light-color);
      color: var(--theme-background-color);
    }

    &.outline .button {
      border: 1px solid var(--theme-light-color);
      background-color: var(--theme-background-color);
      color: var(--theme-light-color);
    }
  }

  &.dark {
    .button {
      background-color: var(--theme-dark-color);
      border: 1px solid var(--theme-dark-color);
      color: var(--theme-background-color);
    }

    &.outline .button {
      border: 1px solid var(--theme-dark-color);
      background-color: var(--theme-background-color);
      color: var(--theme-dark-color);
    }
  }

  &.red {
    .button {
      color: var(--theme-background-color);
      border: 1px solid red;
      background-color: red;
    }

    &.outline .button {
      color: red;
      border: 1px solid red;
      background-color: var(--theme-background-color);
    }
  }

  &.black {
    .button {
      color: var(--theme-background-color);
      border: 1px solid #000;
      background-color: #000;
    }

    &.outline .button {
      color: #000;
      border: 1px solid #000;
      background-color: var(--theme-background-color);
    }
  }

  &.circle .button {
    border-radius: 100px;
  }

  &.big .button {
    min-width: 60px;
    padding: 0px 20px;
    line-height: 30px;
  }

  &.small .button {
    padding: 0px 4px;
    line-height: 20px;
  }
  &.primary,
  &.red,
  &.black,
  &.secondary,
  &.tertiary,
  &.success,
  &.danger,
  &.warning,
  &.info,
  &.light,
  &.dark {
    .button:disabled {
      color:#dfdfdf !important;
      background-color: #bbb !important;
      border-color: #bbb !important;
      cursor: not-allowed;
    }
  }
  &.outline .button:disabled {
    // color:#eaeaea !important;
    // border-color: #eaeaea !important;
    background-color: var(--theme-background-color) !important;
    color:#d5d5d5!important;
    border-color: #d5d5d5 !important;
    cursor: not-allowed;
  }
  .button {
    overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
    position: relative;
    margin: 4px 2.5px;
    width: calc(100% - 5px);
    box-sizing: border-box;
    cursor: pointer;
    text-indent: 0;
    padding: 0px 6px;
    border-radius: var(--theme-input-radius);
    border: 1px solid var(--theme-primary-color);
    background-color: var(--theme-background-color);
    color: var(--theme-primary-color);
    font: 500 14px "Pretendard Variable", Pretendard;
    line-height: 24px;
    &:disabled {
      // color:#eaeaea !important;
      // border-color: #eaeaea !important;
      background-color: var(--theme-background-color) !important;
      color:#d5d5d5!important;
      border-color: #d5d5d5 !important;
      cursor: not-allowed;
    }
  }
}
</style>
