export default {
  namespaced: true,
  state: {
    info: {},
    list: [],
    sublist: [],
    count: 0,
    totalPage: 0,
  },
  getters: {

  },
  mutations: {
    resetState (state) {
      Object.assign(state.count, 0)
      Object.assign(state.list, [])
    },
    setDataInfo (state, data) {
      state.info = data || {}
    },
    setDataList (state, data) {
      state.list = data || {}
    },
    setDataCount (state, data) {
      state.count = data || 0
    },
    setDataTotalPage (state, data) {
      state.totalPage = data || 0
    },
  },
  actions: {
    getCategoryList (context, payload) {

      return new Promise((resolve, reject) => {

        this._vm.get(`${this._vm.SPARK_ONE_CREW_API_URL}common/category`, payload)
          .then((response) => {
            context.commit('setDataList', response.data.categoryList || [])
            context.commit('setDataCount', response.data.categoryCount || 0)

            if (payload && typeof payload.limit !== 'undefined') {
              context.commit(
                'setDataTotalPage',
                Math.ceil((response.data.categoryCount || 0) / (payload.limit)),
              )
            }
            resolve(response)
          })
          .catch((error) => reject(error))

      })

    },
    getCategoryInfo (context, payload) {
      context.commit('setDataInfo', {})

      return new Promise((resolve, reject) => {

        this._vm.get(`${this._vm.SPARK_ONE_CREW_API_URL}common/category/${payload.categoryId}`)
          .then(response => {
            context.commit('setDataInfo', response.data.categoryInfo)
            resolve(response)
          })
          .catch(error => reject(error))

      })

    },
    setCategory (context, payload) {

      return new Promise((resolve, reject) => {

        this._vm.post(`${this._vm.SPARK_ONE_CREW_API_URL}common/category`, payload)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => reject(error))

      })

    },
    putCategory (context, payload) {

      return new Promise((resolve, reject) => {

        this._vm.put(`${this._vm.SPARK_ONE_CREW_API_URL}common/category/${payload.categoryId}`, payload)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))

      })

    },
  },
}
