<template>
  <div class="tooltip">
    <slot></slot>
    <div class="tooltiptext" v-html="message"></div>
  </div>
</template>

<script>
export default {
  name: 'SpLimiter',
  inheritAttrs: false,
  props: {
    message: { type: String, default: '' },
  },
}
</script>
<style lang="scss" scoped>
.tooltip {
  position: relative;
  display: inline-block;
  width: fit-content;
  border: 0;
  margin: 0;
  padding: 0;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: max-content;
  background-color: #fff;
  // text-align: center;
  border-radius: var(--theme-input-radius);
  border:1px  solid black;
  padding: 7px;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 50%;
  transform: translateX(-20px);

}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: calc(100% - 1px);
  left: 0%;
  margin-left: -7px;
  border-width: 7px;
  border-style: solid;
  border-color: transparent transparent #fff transparent;
  transform: translateX(20px);
}

.tooltip .tooltiptext::before {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 0%;
  margin-left: -7px;
  border-width: 7px;
  border-style: solid;
  border-color: transparent transparent black transparent;
  transform: translateX(20px);
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>
