<template>
  <nav v-if="totalPages">
    <ul v-if="originFilter">
      <li>
        <button
        class="button"
        @click.prevent="paginateTo(1)">
          <ion-icon name="caret-back-outline"></ion-icon>
        </button>
      </li>
      <li>
        <button
        class="button"
        @click.prevent="paginateTo(currentPage - 1 > 1 ? currentPage - 1 : 1)">
          <ion-icon name="chevron-back-outline"></ion-icon>
        </button>
      </li>
      <li style="margin-right:4px;">
        <sp-input
        @keyup.enter="paginateTo(nextPage)"
        :style="{width: inputWidth}"
        type="number"
        min="1"
        v-model="nextPage"/><span> / </span><span ref="total">{{ totalPages }}</span>
      </li>
      <li>
        <button
        class="button"
        @click.prevent="paginateTo(
          (currentPage + 1 < totalPages) ? currentPage + 1 : totalPages
        )">
          <ion-icon name="chevron-forward-outline"></ion-icon>
        </button>
      </li>
      <li>
        <button
        class="button"
        @click.prevent="paginateTo(totalPages)">
          <ion-icon name="caret-forward-outline"></ion-icon>
        </button>
      </li>
    </ul>
    <ul v-else>
      <li>
        <router-link
        class="button"
        tag="button"
        :to="paginateObject(1)">
          <ion-icon name="caret-back-outline"></ion-icon>
        </router-link>
      </li>
      <li>
        <router-link
        class="button"
        tag="button"
        :to="paginateObject(currentPage - 1 > 1 ? currentPage - 1 : 1)">
          <ion-icon name="chevron-back-outline"></ion-icon>
        </router-link>
      </li>
      <li style="margin-right:4px;">
        <sp-input
        @keyup.enter="pageEnter(nextPage)"
        :style="{width: inputWidth}"
        type="number"
        min="1"
        :max="totalPages"
        v-model="nextPage"/> / <span ref="total">{{ totalPages }}</span> </li>
      <li>
        <router-link
        class="button"
        tag="button"
        :to="paginateObject(
          (currentPage + 1 < totalPages) ? currentPage + 1 : totalPages
        )">
          <ion-icon name="chevron-forward-outline"></ion-icon>
        </router-link>
      </li>
      <li>
        <router-link
        class="button"
        tag="button"
        :to="paginateObject(totalPages)">
          <ion-icon name="caret-forward-outline"></ion-icon>
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'Pagination',
  data () {
    return {
      currentPage: null,
      nextPage: null,
      inputWidth: '20px',
    }
  },
  props: {
    totalPages: {
      type: Number,
      required: true,
    },
    pageParameter: {
      type: String,
      default: 'page',
    },
    originFilter: {
      type: Object,
      default: null,
    },
  },
  methods: {
    paginateObject (pageTo) {
      return {
        query: {
          ...this.$route.query,
          [this.pageParameter]: pageTo,
        },
      }
    },
    async paginateTo (pageTo) {
      this.currentPage = pageTo
      const payload = {
        ...this.originFilter,
        [this.pageParameter]: pageTo,
      }
      this.$emit('loadData', payload)
    },
    pageEnter (pageTo) {
      if (this.currentPage == pageTo) {
        return
      }
      if (pageTo > this.totalPages) {
        this.nextPage = this.totalPages
      }
      this.$router.push(this.paginateObject(this.nextPage)).catch(e => {})
    },
  },
  mounted () {
    if (this.originFilter) {
      this.currentPage = parseInt(this.originFilter[this.pageParameter], 10) || 1
    } else {
      this.currentPage = parseInt(this.$route.query[this.pageParameter], 10) || 1
    }
    this.$store.commit('setCurrentPage', this.currentPage)
  },
  watch: {
    $route () {
      if (this.originFilter) {
        this.currentPage = parseInt(this.originFilter[this.pageParameter], 10) || 1
      } else {
        this.currentPage = parseInt(this.$route.query[this.pageParameter], 10) || 1
      }
      this.$store.commit('setCurrentPage', this.currentPage)
    },
    currentPage (to) {
      this.nextPage = to
      this.$nextTick(() => {
        this.inputWidth = ((this.$refs.total?.getBoundingClientRect()?.width || 35) + 35) + 'px'
      })
    },
    nextPage () {
      // if (this.nextPage > this.totalPages) this.nextPage = this.totalPages
      if (this.nextPage < 1) this.nextPage = 1
    },
    totalPages () {
      if (this.totalPages) {
        if (this.$route.query.page && this.$route.query.page > this.totalPages) {
          this.$router.replace({
            query: {
              ...this.$route.query,
              page: 1,
            },
          }).catch(e => {})
        }
      }
      this.$nextTick(() => {
        this.inputWidth = ((this.$refs.total?.getBoundingClientRect()?.width || 35) + 35) + 'px'
      })
    },
  },

}
</script>

<style lang="scss" scoped>
nav {

  width:fit-content;
  margin: 20px auto 0px auto;
  font-size:14px;
  ul {
    margin: 0;
    padding :0;
  }
}
li {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
}
.button {
  min-width: 43px;
  color: #777;
  // color: black;
  cursor: pointer;
  margin: 1px;
  height:26px;
  border-radius: var(--theme-input-radius);
  border:1px solid #ddd;
  background-color: var(--theme-background-color);
  line-height:14px;
}
ion-icon {
  vertical-align:top;
}
</style>
