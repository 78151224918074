<template>
  <sp-button
    :class="className"
    v-if="isShow && tagName === 'button'"
    @click.prevent.stop="move"
    :id="uuid"
    v-bind="attrs"
  >{{ text }}</sp-button>
  <a
    v-else-if="isShow && tagName === 'a'"
    @click.prevent.stop="move"
    style="cursor: pointer;">
    {{ text }}
  </a>
  <span
    v-else-if="!isShow && tagName === 'a'"
    >
    {{ text }}
  </span>
</template>

<script>
export default {
  name: 'SpAuthButton',
  inheritattrs: false,
  props: {
    text: { type: String, default: '' },
    className: { type: String, default: '' },
    id: { type: String, default: '' },
    url: { type: String, default: '/' },
    functionType: { type: String, default: '' },
    tagName: { type: String, default: 'button' },
    href: { type: String, default: '' },
  },
  data () {
    return {
      uuid: null,
    }
  },
  computed: {
    isShow () {
      return this.$store.getters.pathToMenu(this.$route.path)?.functionType.indexOf(this.functionType) !== -1
    },
    attrs () {
      return this.$attrs
    },
  },
  created () {
    this.uuid = this.id
    if (this.uuid === '') {
      this.uuid = this.uuidv4()
    }
  },
  methods: {
    move () {
      if (this.href != '') {
        window.open(this.href, '_blank')
      } else if (this.url !== '/') {
        this.$router.push({ path: this.url })
      } else {
        this.$emit('button')
      }
    },
  },
}
</script>
<style lang="scss" scoped>
button:disabled {
  background-color: #ddd;
  border-color: #ddd;
  pointer-events: none;
  cursor:not-allowed;
}
</style>
