const getDefaultState = () => {
  return {
    notices: null,
    count: 0,
    codes: {},
    originFilter: {},
  }
}
export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    totalPages: (state) => {
      const limit = state.originFilter?.limit || 50
      const count = state.count || 0
      return Math.ceil(count / limit)
    },
    codes (state) {
      return state.codes
    },
  },
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setResponse (state, data) {
      state.notices = data.notices
      state.count = data.totalCount
      state.codes = { ...data.codes }
    },
    setOriginFilter (state, filter) {
      state.originFilter = JSON.parse(JSON.stringify(filter))
    },
  },
  actions: {
    async getNotices ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}myofficeb/notices`,
          payload,
        )
        commit('setResponse', response.data)
        commit('setOriginFilter', payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getNoticeApplyTargets ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}myofficeb/notice/apply-targets`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    async postNotice ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.post(
          `${this._vm.SPARK_ONE_CREW_API_URL}myofficeb/notice`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async putNotice ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.put(
          `${this._vm.SPARK_ONE_CREW_API_URL}myofficeb/notice/${payload.id}`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getNotice ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}myofficeb/notice/${payload.id}`,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
  },
}
