<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="155" height="29" viewBox="0 0 251 47" fill="none">
    <path d="M54.6489 3.65967H85.3128V12.007H64.0038V19.8961H84.0941V28.7017H64.0038V44.4446H54.6489V3.65967Z" :fill="color"/>
    <path d="M90.1392 3.65967H120.803V12.007H99.494V19.8961H119.577V28.7017H99.494V44.4446H90.1392V3.65967Z" :fill="color"/>
    <path d="M131.059 1.62109C134.06 1.62109 136.398 3.86303 136.398 6.50683C136.398 9.35507 134.06 11.597 131.059 11.597C128.058 11.597 125.621 9.35507 125.621 6.50683C125.621 3.86303 127.959 1.62109 131.059 1.62109ZM126.586 14.8542H135.384V44.4435H126.586V14.8542Z" :fill="color"/>
    <path d="M193.26 3.65967H224.431V12.007H202.615V19.4237H223.213V28.2293H202.615V36.1043H225.044V44.4516H193.26V3.65967Z" :fill="color"/>
    <path d="M178.968 27.8271C177.249 33.016 171.973 36.5622 165.379 36.5622C157.447 36.5622 151.037 31.4931 151.037 24.0764C151.037 16.6597 157.342 11.5413 165.379 11.5413C171.747 11.5413 176.742 14.8831 178.686 19.6066L188.019 16.5539C184.688 8.1925 175.819 2.82031 165.33 2.82031C152.058 2.82031 141.224 11.4356 141.224 24.0764C141.224 36.7173 152.108 45.2832 165.33 45.2832C176.34 45.2832 185.498 39.3822 188.442 30.4074L178.975 27.8271H178.968Z" :fill="color"/>
    <path d="M1.61914 24.0764C1.61914 11.4356 12.4533 2.82031 25.7248 2.82031C38.9963 2.82031 49.8304 11.5413 49.8304 24.0764C49.8304 36.6115 39.1512 45.2832 25.7248 45.2832C12.2983 45.2832 1.61914 36.7173 1.61914 24.0764ZM25.7741 36.5622C33.861 36.5622 39.9613 31.2323 39.9613 24.0764C39.9613 16.9205 34.0089 11.5413 25.7741 11.5413C17.5393 11.5413 11.4319 16.8148 11.4319 24.0764C11.4319 31.338 17.8422 36.5622 25.7741 36.5622Z" :fill="color"/>
    <path d="M239.619 22.1294C244.404 22.1294 248.283 18.247 248.283 13.4578C248.283 8.66856 244.404 4.78613 239.619 4.78613C234.833 4.78613 230.954 8.66856 230.954 13.4578C230.954 18.247 234.833 22.1294 239.619 22.1294Z" :stroke="color" stroke-width="2.19883" stroke-miterlimit="10"/>
    <path d="M235.73 8.69308H240.626C242.895 8.69308 244.155 9.71535 244.155 11.1465C244.155 12.0771 243.55 13.0007 242.31 13.3039V13.325C243.571 13.5436 244.578 14.164 244.578 15.581C244.578 17.7525 242.261 18.2037 240.577 18.2037H235.73V8.68604V8.69308ZM239.894 12.4649C240.746 12.4649 241.767 12.4014 241.767 11.5766C241.767 10.7517 240.739 10.6953 239.922 10.6953H238.013V12.4649H239.894ZM240.105 16.2085C240.95 16.2085 242.134 16.1451 242.134 15.2708C242.134 14.3966 240.971 14.3332 240.154 14.3332H238.013V16.2156H240.105V16.2085Z" :fill="color"/>
  </svg>
</template>
<script>
export default {
  name: 'LogoOfficeB',
  props: {
    color: {
      type: String,
      default: '#FFF',
    },
    size: {
      type: [String, Number],
      default: 191,
    },
  },
}
</script>
