const getDefaultState = () => {
  return {
    workOrders: null,
    workOrder: null,
    count: 0,
    codes: {},
    originFilter: {},
  }
}
export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    totalPages: (state) => {
      const limit = state.originFilter?.limit || 50
      const count = state.count || 0
      return Math.ceil(count / limit)
    },
    codes (state) {
      return state.codes
    },
  },
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setWorkOrders (state, data) {
      state.workOrders = data.workOrders
      state.count = data.totalCount
      state.codes = { ...data.codes }
    },
    setWorkOrder (state, data) {
      state.workOrder = data.workOrder
      state.codes = { ...data.codes }
    },
    setOriginFilter (state, filter) {
      state.originFilter = JSON.parse(JSON.stringify(filter))
    },
  },
  actions: {
    async getWorkOrders ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}myofficeb/work-orders`,
          payload,
        )
        commit('setWorkOrders', response.data)
        commit('setOriginFilter', payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getWorkOrder ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}myofficeb/work-order/${payload.id}`,
        )
        commit('setWorkOrder', response.data)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    async putWorkOrder ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.put(
          `${this._vm.SPARK_ONE_CREW_API_URL}myofficeb/work-order/${payload.id}`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
  },
}
