const getDefaultState = () => {
  return {
    branchList: [],
    goodsList: [],
    goodsOneTimeList: [],
    tenantListBusiness: [],
    tenantListPersonal: [],
    contractListPersonal: [],
    accountList: [],
  }
}
export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {

  },
  mutations: {
    setBranchResponse (state, data) {
      state.branchList = data.branchList
    },
    setGoodsResponse (state, data) {
      state.goodsList = data.goodsList
    },
    setGoodsOneTimeResponse (state, data) {
      state.goodsOneTimeList = data.goodsList
    },
    setTenantBusinessResponse (state, data) { // 기업 - 사업자
      state.tenantListBusiness = data.tenantList
    },
    setTenantPersonalResponse (state, data) { // 기업 - 개인
      state.tenantListPersonal = data.tenantList
    },
    setContractResponse (state, data) {
      state.contractListPersonal = data.contractList
    },
    setAccountResponse (state, data) {
      state.accountList = data.accountList
    },

  },
  actions: {
    // 정산 > 공통 필터 지점 조회 API
    async getCommonFilterBranch ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
                        `${this._vm.SPARK_ONE_CREW_API_URL}settlement/common/filter/branch`,
                        payload,
        )
        commit('setBranchResponse', response.data)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    // 정산 > 공통 필터 상세 조회 API
    async getCommonFilterGoods ({ commit }, oneTimeYn) {
      const payload = {
        oneTimeYn,
      }

      let response = null
      try {
        response = await this._vm.get(
                          `${this._vm.SPARK_ONE_CREW_API_URL}settlement/common/filter/goods`,
                          payload,
        )
        if (oneTimeYn == 'Y') {
          commit('setGoodsOneTimeResponse', response.data)
        } else {
          commit('setGoodsResponse', response.data)
        }
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    // 정산 > 공통 필터 기업(사업자/개인) 조회 API
    async getCommonFilterTenant ({ commit }, type) {
      let response = null
      const payload = {
        tenantType: type,
      }
      try {
        response = await this._vm.get(
                          `${this._vm.SPARK_ONE_CREW_API_URL}settlement/common/filter/tenant`,
                          payload,
        )
        if (type === 'business') { // 사업자
          commit('setTenantBusinessResponse', response.data)
        } else if (type === 'personal') { // 개인
          commit('setTenantPersonalResponse', response.data)
        }

      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // 정산 등록/조회 시 계약 리스트 조회 API
    async getCommonFilterContract ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
                          `${this._vm.SPARK_ONE_CREW_API_URL}settlement/common/filter/contract`,
                          payload,
        )
        commit('setContractResponse', response.data)

      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // 정산 등록/조회 시 어카운트 리스트 조회 API
    async getCommonFilterAccount ({ commit }, id) {
      let response = null
      const payload = {
        tenantId: id,
      }
      try {
        response = await this._vm.get(
                          `${this._vm.SPARK_ONE_CREW_API_URL}settlement/common/filter/account`,
                          payload,
        )
        commit('setAccountResponse', response.data)

      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

  },
}
